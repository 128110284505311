import * as React from 'react';

import { Col, Button } from 'react-bootstrap';

import { HEADER_LOGIN_BUTTON } from '../../constants/labels';

import { HeaderLoginProps } from '../../@types/Header.d';

const HeaderLogin: React.FC<HeaderLoginProps> = (props: HeaderLoginProps) => {
  const { showLoginModal } = props;

  const onClickLogin = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    showLoginModal(true);
    event.preventDefault();
  };

  return (
    <Col className="header-login-container p-0">
      <Button className="ci-button" onClick={onClickLogin}>
        {HEADER_LOGIN_BUTTON}
      </Button>
    </Col>
  );
};

export default HeaderLogin;
