import * as React from 'react';

import { Col, Row, ListGroup, Form } from 'react-bootstrap';
import {
  faTrash,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CirculationItem from '../../Common/CirculationItem';
import AreaList from './AreaList';
import CustomTooltip from '../../Common/CustomTooltip';

import { getTotalCirculationAreas } from '../../../util/areaUtil';
import {
  removeSubsidiary,
  hideSubsidiarySelection,
} from '../../../util/iFrameMessageUtil';

import {
  TOOLTIP_SELECTED_SUBSIDIARY_REMOVE,
  TOOLTIP_SELECTED_SUBSIDIARY_HIDE,
  PRICE_SUM_LABEL,
} from '../../../constants/labels';

import {
  SubsidiaryAreaListItemProps,
  SubsidiaryAreaListItemState,
} from '../../../@types/AreaList.d';

export default class SubsidiaryAreaListItem extends React.Component<
  SubsidiaryAreaListItemProps,
  SubsidiaryAreaListItemState
> {
  constructor(props: SubsidiaryAreaListItemProps) {
    super(props);

    const { show } = this.props;

    this.state = { show };

    this.onClickCollapse = this.onClickCollapse.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onChangeHideSelection = this.onChangeHideSelection.bind(this);
  }

  componentDidUpdate(prevProps: SubsidiaryAreaListItemProps): void {
    const { show } = this.props;

    // eslint-disable-next-line react/no-did-update-set-state
    if (prevProps.show !== show) this.setState({ show });
  }

  onClickCollapse(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
    event.stopPropagation();
    const { show } = this.state;
    this.setState({ show: !show });
  }

  onClickRemove(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
    event.stopPropagation();
    const { subsidiary } = this.props;

    removeSubsidiary(subsidiary);
  }

  onChangeHideSelection(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    const { subsidiary } = this.props;

    hideSubsidiarySelection(subsidiary, event.currentTarget.checked);
  }

  render(): JSX.Element {
    const { subsidiary, weekpart, summary } = this.props;
    const { show } = this.state;

    return (
      <ListGroup.Item className="subsidiary-area-list-item">
        <Row className="subsidiary-area-list-row">
          <Col xs={1} sm={1} md={1} className="p-0 pl-2">
            <FontAwesomeIcon
              className="hover-icon"
              onClick={this.onClickCollapse}
              icon={show ? faChevronDown : faChevronUp}
            />
          </Col>
          <Col className={`p-0 ${summary ? 'pr-2' : ''}`}>
            <Row className="no-gutters">
              <Col className="custom-text-wrap text-bold p-0">
                {subsidiary.name}
              </Col>
              <Col xs={3} sm={4} md={4} className="p-0">
                <CirculationItem
                  weekpart={weekpart}
                  circulation={getTotalCirculationAreas(subsidiary.areas)}
                />
              </Col>
            </Row>
            {/* <Row className="no-gutters">
              <Col className="p-0 text-right">
                {PRICE_SUM_LABEL(
                  subsidiary.price?.total,
                  subsidiary.price?.totalPerThousand
                )}
              </Col>
            </Row> */}
          </Col>
          {!summary && (
            <Col xs={2} sm={2} md={2} className="p-0 pr-2">
              <Row className="no-gutters subsidiary-icons">
                <Col xs={6} className="text-right">
                  <CustomTooltip
                    tooltipId={`selected-subsidiary-${subsidiary.id}-hide`}
                    content={TOOLTIP_SELECTED_SUBSIDIARY_HIDE}
                  >
                    <Form.Check
                      id={`hide-selection-check-${subsidiary.id}`}
                      custom
                      type="checkbox"
                      onChange={this.onChangeHideSelection}
                      checked={subsidiary.show}
                      label=""
                    />
                  </CustomTooltip>
                </Col>
                <Col xs={6} className="text-right">
                  <CustomTooltip
                    tooltipId={`subsidiary-${subsidiary.id}-rem`}
                    content={TOOLTIP_SELECTED_SUBSIDIARY_REMOVE}
                  >
                    <FontAwesomeIcon
                      className="icon-remove hover-icon"
                      onClick={this.onClickRemove}
                      icon={faTrash}
                    />
                  </CustomTooltip>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        {subsidiary.areas && subsidiary.areas.length > 0 && (
          <Row className="subsidiary-area-list-row">
            <AreaList
              show={show}
              summary={summary}
              weekpart={weekpart}
              areas={subsidiary.areas}
            />
          </Row>
        )}
      </ListGroup.Item>
    );
  }
}
