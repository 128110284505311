/* eslint-disable no-self-compare */
import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpenText,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons';

import CustomTooltip from './CustomTooltip';

import {
  CIRCULATION_PIECES_SHORT,
  CIRCULATION_TITLE,
  CIRCULATION_TITLE_MIDWEEK_SHORT,
  CIRCULATION_TITLE_WEEKEND_SHORT,
  TOOLTIP_DISTRIBUTION_TYPE,
} from '../../constants/labels';
import { WEEKPART_MIDWEEK, WEEKPART_BEST } from '../../constants/constants';

import { CirculationItemProps } from '../../@types/App.d';

const CirculationItem: React.FC<CirculationItemProps> = (
  props: CirculationItemProps
) => {
  const {
    weekpart,
    areaWeekpart,
    distributionTitle,
    circulation,
    totalCirculation,
    distributionType,
    areaKey,
  } = props;
  const included: boolean =
    Number.isNaN(+circulation) || typeof circulation === 'string';

  let circulationWidth = 12;

  // if (included) circulationWidth = 11;
  if (totalCirculation) circulationWidth = 6;

  const weekpartShort =
    areaWeekpart === WEEKPART_MIDWEEK
      ? CIRCULATION_TITLE_MIDWEEK_SHORT
      : CIRCULATION_TITLE_WEEKEND_SHORT;

  return (
    <Row
      className={`no-gutters p-0 ${
        totalCirculation ? 'total-circulation' : ''
      }`}
    >
      <Col sm={12} className="p-0">
        <Row className="no-gutters">
          {totalCirculation && (
            <Col xs={6} className="p-0 custom-text-wrap distribution-type-icon">
              {CIRCULATION_TITLE(undefined, true)}
            </Col>
          )}
          <Col xs={circulationWidth} className="text-right p-0">
            <Row className="no-gutters">
              <Col className="p-0 custom-text-wrap text-right">
                {!included
                  ? `${circulation.toLocaleString()} ${CIRCULATION_PIECES_SHORT} ${
                      areaWeekpart &&
                      weekpart === WEEKPART_BEST &&
                      !totalCirculation
                        ? `(${weekpartShort})`
                        : ''
                    }`
                  : circulation}
              </Col>
              {distributionType && (
                <Col xs={3} xl={2} className="p-0">
                  <CustomTooltip
                    tooltipId={`area-distriution-type-${areaKey}-tooltip`}
                    content={TOOLTIP_DISTRIBUTION_TYPE(
                      distributionType,
                      distributionTitle
                    )}
                  >
                    <FontAwesomeIcon
                      className="pl-1 tooltip-icon"
                      icon={
                        distributionType === 'DIRECT'
                          ? faEnvelopeOpenText
                          : faNewspaper
                      }
                    />
                  </CustomTooltip>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CirculationItem;
