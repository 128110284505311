import '../styles/App.scss';
import '../styles/Modal.scss';

import 'moment/locale/de';

import React from 'react';

import { withCookies } from 'react-cookie';
import { head, find, forEach, remove } from 'lodash';
import { Moment } from 'moment';

import { Editor } from '@aurigma/design-editor-iframe/Editor';
import DistributionPlaningContainer from './DistributionPlaning/DistributionPlaningContainer';
import LayoutDesignContainer from './LayoutDesign/LayoutDesignContainer';
import LoginModal from './Modal/LoginModal';
import StepSelectionContainer from './StepSelection/StepSelectionContainer';
import LoadingOverlay from './Common/LoadingOverlay';
import {
  getSubsidiariesSend,
  getAreasSend,
  getBas64FromFile,
} from '../util/convetUtil';
import ChangePasswordModal from './Modal/ChangePasswordModal';
import ResponseModal from './Modal/ResponseModal';
import ConfirmationModal from './Modal/ConfirmationModal';
import OrderModal from './Modal/OrderModal/OrderModal';

import {
  getTotalCirculationAreas,
  getTotalCirculationSubsidiaries,
} from '../util/areaUtil';
import { changeProduct, addOrder } from '../util/iFrameMessageUtil';
import {
  getLogin,
  putChangePassword,
  getClients,
  getClientData,
  postOrderRequest,
  postOfferRequest,
  getTotalPrice,
} from '../util/api';

import {
  STEP_DISTRIBUTION,
  STEP_INIT,
  COOKIE_USER_NAME,
  REQUEST_IDENTIFIER_GET_CLIENTS,
  REQUEST_IDENTIFIER_GET_CLIENT_DATA,
  REQUEST_IDENTIFIER_CHANGE_PASSWORD,
  REQUEST_IDENTIFIER_LOGIN,
  REQUEST_IDENTIFIER_SEND_OFFER_ORDER,
  DEFAULT_PRODUCTS,
  TRANSMISSION_TYPE_ORDER,
  STEP_LAYOUT,
  STEP_FORMAT,
} from '../constants/constants';
import {
  PARAM_PARENTORIGIN,
  PARAM_CLIENT_ID,
  PARAM_SUBSIDIARYMODE,
  PARAM_EMAIL,
  PARAM_WEEKPART,
} from '../constants/network';
import config from '../config';
import {
  RESPONSE_MODAL_SUCCESS_TITLE,
  RESPONSE_MODAL_SUCCESS_CONTENT,
  RESPONSE_MODAL_FAILURE_TITLE,
  RESPONSE_MODAL_FAILURE_CONTENT,
  LOADING_PLEASE_WAIT,
  LOADING_PROCESS_REQUEST,
  BUTTON_TITLE_SEND_REQUEST,
} from '../constants/labels';

import { AppProps, AppState, StepWizardHooks } from '../@types/App.d';
import {
  User,
  Client,
  ClientLocation,
  Salutation,
  Product,
  SubsidiarySendFormat,
  RequestPayload,
  Price,
  AdditionalOptions,
  Weekpart,
  DateType,
  ExtraCopy,
  MasterFile,
  BillingType,
  PriceResult,
  TotalPrice,
  PrintTemplate,
  LayoutSelection,
} from '../@types/Common.d';
import MessageType, {
  Message,
  LocalityMessagePayload,
  AreaMessagePayload,
  HideSubsidiarySelectionPayload,
} from '../@types/MessageTypes.d';
import { Area } from '../@types/Area.d';
import HeaderContainer from './Header/HeaderContainer';
import StepWizard from './Common/StepWizard';
import FormatSelectionContainer from './LayoutDesign/FormatSelectionContainer';
import LayoutPreviewModal from './Modal/LayoutPreviewModal';
import ContinueModal from './Modal/ContinueModal';

class App extends React.Component<AppProps, AppState> {
  confirmationModalRef = React.createRef<ConfirmationModal>();

  responseModalRef = React.createRef<ResponseModal>();

  stepWizardRef = React.createRef<StepWizard>();

  formatSelectionRef = React.createRef<FormatSelectionContainer>();

  layoutPreviewModalRef = React.createRef<LayoutPreviewModal>();

  layoutDesignContainerRef = React.createRef<LayoutDesignContainer>();

  constructor(props: AppProps) {
    super(props);

    const { cookies } = this.props;
    const { subsidiaryMode, defaultWeekpart } = config;

    this.state = {
      showLoginModal: false,
      showChangePasswordModal: false,
      showOrderModal: false,
      showConfirmationModal: false,
      showResponseModal: false,
      showLayoutPreviewModal: false,
      showContinueModal: false,
      isLoading: false,
      pendingRequests: [],
      user: cookies.get('user') || undefined,
      subsidiaryMode: cookies.get('user') || subsidiaryMode,
      weekpart: defaultWeekpart as Weekpart,
      totalCirculation: 0,
      iFrameURL: '',
      areas: [] as Area[],
      selectedSubsidiaries: [] as ClientLocation[],
      selectedProduct: DEFAULT_PRODUCTS[0],
    };

    this.advanceStep = this.advanceStep.bind(this);
    this.submitOfferRequest = this.submitOfferRequest.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
    this.enableLoadingOverlay = this.enableLoadingOverlay.bind(this);

    this.findArea = this.findArea.bind(this);

    this.showLoginModal = this.showLoginModal.bind(this);
    this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
    this.showOrderModal = this.showOrderModal.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.showResponseModal = this.showResponseModal.bind(this);
    this.showLayoutPreviewModal = this.showLayoutPreviewModal.bind(this);
    this.showContinueModal = this.showContinueModal.bind(this);

    this.submitLogin = this.submitLogin.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.changeSelectedClient = this.changeSelectedClient.bind(this);
    this.changeIFrameURL = this.changeIFrameURL.bind(this);

    this.onReceiveMessage = this.onReceiveMessage.bind(this);
    this.removeSubsidiaryFromSelection = this.removeSubsidiaryFromSelection.bind(
      this
    );

    this.getTotalCirculation = this.getTotalCirculation.bind(this);
    this.getPrice = this.getPrice.bind(this);

    this.setSelectedProduct = this.setSelectedProduct.bind(this);
    this.setSelectedPrintTemplate = this.setSelectedPrintTemplate.bind(this);
    this.approveLayout = this.approveLayout.bind(this);
    this.resetLayoutDesign = this.resetLayoutDesign.bind(this);
  }

  componentDidMount(): void {
    const { user } = this.state;

    window.addEventListener('message', this.onReceiveMessage);
    this.changeIFrameURL();

    if (user) this.getUserClients();
  }

  onReceiveMessage(event: MessageEvent): void {
    let { data } = event;
    if ((data as Message).type) {
      data = data as Message;

      const { payload, type } = data;

      if (type === MessageType.MESSAGE_TYPE_UPDATE_AREAS) {
        if ((payload as AreaMessagePayload).areas.length > 0) {
          const { weekpart, totalCirculation, price } = payload;
          let selectedSubsidiaries = [] as ClientLocation[];
          let areas = [] as Area[];

          if ((payload.areas as Area[])[0].areaKey) {
            areas = payload.areas as Area[];
          } else if (
            ((payload as AreaMessagePayload).areas as ClientLocation[])[0].id
          ) {
            selectedSubsidiaries = (payload.areas as ClientLocation[]).filter(
              (location, index, self) =>
                index ===
                self.findIndex(pLocation => pLocation.id === location.id)
            );
          }

          this.setState({
            price,
            areas,
            selectedSubsidiaries,
            weekpart,
            totalCirculation,
          });
        } else {
          const { weekpart, totalCirculation, price } = payload;
          this.setState({
            price,
            areas: [],
            selectedSubsidiaries: [],
            weekpart,
            totalCirculation,
          });
        }
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES) {
        if ((data.payload as LocalityMessagePayload).area) {
          const { areas, selectedSubsidiaries } = this.state;
          const {
            area,
            totalCirculation,
          } = data.payload as LocalityMessagePayload;

          const fArea = this.findArea(area);

          if (!fArea) return;

          fArea.localities = area.localities;
          fArea.circulation = area.circulation;

          this.setState({
            areas,
            selectedSubsidiaries,
            totalCirculation: totalCirculation ?? 0,
          });
        }
      } else if (type === MessageType.MESSAGE_TYPE_ADD_SUBSIDIARY) {
        if ((data.payload as ClientLocation).id) {
          const { selectedSubsidiaries } = this.state;

          // selectedSubsidiaries.push(data.payload as ClientLocation);
          this.setState({
            selectedSubsidiaries: [
              ...selectedSubsidiaries,
              ...[data.payload as ClientLocation],
            ],
          });
        }
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_SUBSIDIARY) {
        if (data.payload)
          this.removeSubsidiaryFromSelection(data.payload as ClientLocation);
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_PRICE) {
        if (data.payload) this.setPrice(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_HIDE_SUBSIDIARY_SELECTION) {
        if (data.payload) {
          const { selectedSubsidiaries } = this.state;
          const hPayload = data.payload as HideSubsidiarySelectionPayload;

          const fSubsidiary = find(selectedSubsidiaries, {
            id: hPayload.subsidiaryId,
          });

          if (fSubsidiary) {
            fSubsidiary.show = hPayload.show;

            this.setState({ selectedSubsidiaries });
          }
        }
      }
    }
  }

  getTotalCirculation(): number {
    const { areas, subsidiaryMode, selectedSubsidiaries } = this.state;
    if (subsidiaryMode)
      return getTotalCirculationSubsidiaries(selectedSubsidiaries);
    return getTotalCirculationAreas(areas);
  }

  async getUserClients(): Promise<void> {
    const { user } = this.state;

    if (user) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENTS);
      const clients = (await getClients(user.email)) as Client[];

      clients.sort((a, b) => {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
        return 0;
      });

      if (clients) {
        this.setState({ clients }, () => {
          this.changeSelectedClient(clients[0]);
          this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENTS);
        });
      }
    }
  }

  async getPrice(
    printPerSubsidiary: boolean = false,
    additionalOptions?: AdditionalOptions
  ): Promise<void> {
    const {
      areas,
      selectedSubsidiaries,
      weekpart,
      selectedProduct,
    } = this.state;

    const subsidiariesSend =
      selectedSubsidiaries.length > 0
        ? await Promise.all(
            getSubsidiariesSend(
              selectedSubsidiaries,
              [],
              [],
              printPerSubsidiary
            )
          )
        : ([
            {
              id: -1,
              areas: getAreasSend(areas),
            },
          ] as SubsidiarySendFormat[]);

    const { price, subsidiaryPrices } = (await getTotalPrice(
      subsidiariesSend,
      weekpart,
      printPerSubsidiary,
      selectedProduct,
      additionalOptions
    )) as PriceResult;

    selectedSubsidiaries.forEach(
      // eslint-disable-next-line no-return-assign
      subsidiary =>
        (subsidiary.price = subsidiaryPrices?.find(
          (subsidiaryPrice: Price) => subsidiaryPrice.id === subsidiary.id
        ))
    );

    this.setState({ price, selectedSubsidiaries });
  }

  setSelectedProduct(selectedProduct: Product): void {
    this.setState({ selectedProduct }, () => changeProduct(selectedProduct));
  }

  setPrice(price: TotalPrice): void {
    this.setState({ price });
  }

  setSelectedPrintTemplate(selectedPrintTemplate?: PrintTemplate): void {
    this.setState({ selectedPrintTemplate }, () => {
      const { current } = this.stepWizardRef;

      if (current !== null && selectedPrintTemplate) current.nextStep();
    });
  }

  resetLayoutDesign() {
    const { current } = this.layoutDesignContainerRef;

    if (current === null) return;

    current.resetState();
  }

  async approveLayout(): Promise<void> {
    const { current } = this.layoutDesignContainerRef;

    if (current !== null) {
      current.approveLayout();
      this.showContinueModal(true);
    }
  }

  findArea(area: Area): Area | undefined {
    const { selectedSubsidiaries, areas, subsidiaryMode } = this.state;
    let fArea: Area | undefined;
    if (subsidiaryMode) {
      const subsidiary = find(selectedSubsidiaries, { id: area.subsidiaryId });
      if (!subsidiary) return fArea;

      fArea = find(subsidiary.areas, { areaKey: area.areaKey });

      if (!fArea) {
        forEach(subsidiary.areas, pArea => {
          fArea = find(pArea.additionalAreas, { areaKey: area.areaKey });
          if (fArea) return false;
          return true;
        });
      }
    } else {
      fArea = find(areas, { areaKey: area.areaKey });

      if (!fArea) {
        forEach(areas, pArea => {
          fArea = find(pArea.additionalAreas, { areaKey: area.areaKey });
          if (fArea) return false;
          return true;
        });
      }
    }

    return fArea;
  }

  advanceStep(step: number): void {
    const { current } = this.stepWizardRef;

    if (current !== null) current.advanceToStep(step);
  }

  changePassword(oldPassword: string, newPassword: string): void {
    const { user } = this.state;

    if (!user) return;

    putChangePassword(user.email, oldPassword, newPassword);
  }

  async changeSelectedClient(
    selectedClient: Client | undefined
  ): Promise<void> {
    if (!selectedClient) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_DATA);

    const client = (await getClientData(selectedClient.id)) as Client;

    if (Number.isNaN(+client)) {
      this.advanceStep(
        selectedClient?.planLayout ? STEP_INIT : STEP_DISTRIBUTION
      );

      this.setState({ selectedClient: client, price: undefined }, () => {
        this.changeIFrameURL();
        this.setSelectedProduct(head(client.products));
      });
    }

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
  }

  changeIFrameURL(): void {
    const { user, selectedClient } = this.state;
    const { defaultWeekpart } = config;

    const iFrameURL = `${config.mapUrl}?${PARAM_PARENTORIGIN}=${
      // const iFrameURL = `http://10.54.220.88:3006?${PARAM_PARENTORIGIN}=${
      window.location.origin
    }${selectedClient ? `&${PARAM_CLIENT_ID}=${selectedClient.id}` : ''}${
      user ? `&${PARAM_SUBSIDIARYMODE}=1&${PARAM_EMAIL}=${user.email}` : ''
    }&${PARAM_WEEKPART}=${selectedClient?.weekparts[0] ??
      defaultWeekpart}&TIME=${new Date().getTime() / 100}`;

    this.setState({
      iFrameURL,
      areas: [] as Area[],
      selectedSubsidiaries: [] as ClientLocation[],
    });
  }

  logoutUser(): void {
    this.setState(
      {
        user: undefined,
        clients: undefined,
        selectedClient: undefined,
        subsidiaryMode: false,
        selectedProduct: DEFAULT_PRODUCTS[0],
        price: undefined,
      },
      () => {
        const { cookies } = this.props;

        cookies.remove(COOKIE_USER_NAME);
        this.changeIFrameURL();
        this.advanceStep(STEP_DISTRIBUTION);
      }
    );
  }

  removeSubsidiaryFromSelection(subsidiary: ClientLocation): void {
    const { selectedSubsidiaries } = this.state;

    remove(
      selectedSubsidiaries,
      selectedSubsidiary => selectedSubsidiary.id === subsidiary.id
    );

    this.setState({ selectedSubsidiaries }, () =>
      this.setState({ totalCirculation: this.getTotalCirculation() })
    );
  }

  showLoginModal(showLoginModal: boolean): void {
    this.setState({ showLoginModal });
  }

  showChangePasswordModal(showChangePasswordModal: boolean): void {
    this.setState({ showChangePasswordModal });
  }

  showOrderModal(showOrderModal: boolean): void {
    this.setState({ showOrderModal });
  }

  showConfirmationModal(
    showConfirmationModal: boolean,
    title: string,
    content: string,
    callback: () => any
  ): void {
    const { current } = this.confirmationModalRef;

    if (current === null) return;

    current.setModalContents(title, content, callback);

    this.setState({
      showConfirmationModal,
    });
  }

  showResponseModal(
    showResponseModal: boolean,
    title: string,
    content: string
  ): void {
    const { current } = this.responseModalRef;

    if (current === null) return;

    current.setModalContents(title, content);

    this.setState({
      showResponseModal,
    });
  }

  showLayoutPreviewModal(
    showLayoutPreviewModal: boolean,
    layoutPreview?: Editor.IProofResult,
    isPreview?: boolean
  ): void {
    const { current } = this.layoutPreviewModalRef;

    if (current === null) return;

    current.setLayout(layoutPreview, isPreview);

    this.setState({
      showLayoutPreviewModal,
    });
  }

  showContinueModal(showContinueModal: boolean): void {
    this.setState({ showContinueModal });
  }

  async submitChangePassword(
    oldPassword: string,
    newPassword: string,
    callback?: Function
  ): Promise<void> {
    const { user } = this.state;

    if (user) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CHANGE_PASSWORD);
      const status = await putChangePassword(
        user.email,
        oldPassword,
        newPassword
      );

      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CHANGE_PASSWORD);
      if (callback) callback(status);
    }
  }

  async submitLogin(
    email: string,
    password: string,
    callback?: Function
  ): Promise<void> {
    const { cookies } = this.props;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOGIN);

    const user = (await getLogin(email, password)) as User;

    if (user.id) {
      this.setState(
        {
          user,
          subsidiaryMode: true,
        },
        () => {
          cookies.set(COOKIE_USER_NAME, user);
          this.getUserClients();
        }
      );
    }

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOGIN);
    if (callback) callback(user.id ? 200 : 401);
  }

  async submitOfferRequest(
    actionName: string,
    distributionDate: Moment | null,
    email: string,
    extraCopies: ExtraCopy[],
    lastname: string,
    masterFiles: (MasterFile | LayoutSelection)[],
    billingType: BillingType,
    phone: string,
    prename: string,
    additionalOptions: AdditionalOptions,
    dateType: DateType,
    printPerSubsidiary: boolean,
    billingSubsidiary?: ClientLocation,
    company?: string,
    message?: string,
    salutation?: Salutation
  ): Promise<void> {
    const {
      areas,
      selectedSubsidiaries,
      subsidiaryMode,
      weekpart,
      selectedClient,
      user,
      selectedProduct,
    } = this.state;

    let locations = [] as SubsidiarySendFormat[];

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_SEND_OFFER_ORDER);

    if (subsidiaryMode)
      locations = await Promise.all(
        getSubsidiariesSend(
          selectedSubsidiaries,
          masterFiles,
          extraCopies,
          printPerSubsidiary
        )
      ).then(result => result as SubsidiarySendFormat[]);
    else {
      locations = [
        {
          id: -1,
          areas: getAreasSend(areas),
          extraCopies: extraCopies[0]?.extraCopies ?? 0,
        },
      ] as SubsidiarySendFormat[];
    }

    const masterFileObj = masterFiles.find(
      pMasterFile => pMasterFile.subsidiaryId === -1
    );
    let masterFile;

    if ((masterFileObj as MasterFile)?.masterFile) {
      masterFile = (await getBas64FromFile(
        (masterFileObj as MasterFile)?.masterFile
      )) as string;
    } else if ((masterFileObj as LayoutSelection)?.layoutId)
      masterFile = (masterFileObj as LayoutSelection).layoutId;

    let payload = {
      ...(selectedClient ? { clientId: selectedClient.id } : {}),
      ...(user ? { userId: user.id } : {}),
      actionName,
      locations,
      company,
      email,
      forname: prename,
      message,
      phone,
      productId: selectedProduct.id,
      salutation,
      surename: lastname,
      billingSubsidiary,
      billingType,
      ...(distributionDate
        ? { distributionDay: distributionDate.format('YYYY-MM-DD') }
        : {}),
      weekpart,
      additionalOptions,
      dateType,
      ...(!printPerSubsidiary
        ? {
            masterFile,
          }
        : {}),
    } as RequestPayload;

    let response: any;
    if (
      selectedClient &&
      selectedClient?.transmissionType === TRANSMISSION_TYPE_ORDER
    ) {
      payload = { ...payload, ...{ order_request_id: -1 } };
      response = await postOrderRequest(payload);
    } else {
      payload = { ...payload, ...{ offer_request_id: -1 } };
      response = await postOfferRequest(payload);
    }

    if (response.status < 300) {
      this.showResponseModal(
        true,
        RESPONSE_MODAL_SUCCESS_TITLE,
        RESPONSE_MODAL_SUCCESS_CONTENT
      );

      addOrder({
        ...payload,
        ...{ id: response.data },
        ...{ additionalOptions: {} },
      });
    } else
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT(response.status)
      );
    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_SEND_OFFER_ORDER);
  }

  enableLoadingOverlay(loading: boolean, requestIdentifier: string): void {
    const { pendingRequests } = this.state;

    let nPendingRequests = [] as string[];

    if (loading) nPendingRequests = [requestIdentifier, ...pendingRequests];
    else {
      const index = pendingRequests.indexOf(requestIdentifier);
      if (index < 0) nPendingRequests = pendingRequests;
      else {
        nPendingRequests = [
          ...pendingRequests.slice(0, index),
          ...pendingRequests.slice(index + 1),
        ];
      }
    }

    this.setState({
      pendingRequests: nPendingRequests,
      isLoading: nPendingRequests.length > 0,
    });
  }

  render(): JSX.Element {
    const {
      user,
      clients,
      iFrameURL,
      selectedClient,
      showLoginModal,
      showOrderModal,
      showChangePasswordModal,
      showConfirmationModal,
      showLayoutPreviewModal,
      showResponseModal,
      showContinueModal,
      areas,
      selectedSubsidiaries,
      totalCirculation,
      weekpart,
      isLoading,
      subsidiaryMode,
      selectedProduct,
      selectedPrintTemplate,
      price,
    } = this.state;

    // const stepWizardHooks = {
    //   [STEP_FORMAT]: this.resetLayoutDesign,
    // } as StepWizardHooks;

    return (
      <div className="app-container">
        {isLoading && (
          <LoadingOverlay
            loadingTitle={LOADING_PLEASE_WAIT}
            loadingSubtitle={LOADING_PROCESS_REQUEST}
          />
        )}
        <LoginModal
          show={showLoginModal}
          showModal={this.showLoginModal}
          submitLogin={this.submitLogin}
        />
        <ChangePasswordModal
          show={showChangePasswordModal}
          showModal={this.showChangePasswordModal}
          submitNewPassword={this.submitChangePassword}
        />
        <ConfirmationModal
          ref={this.confirmationModalRef}
          show={showConfirmationModal}
          showModal={this.showConfirmationModal}
        />
        <ResponseModal
          ref={this.responseModalRef}
          show={showResponseModal}
          showModal={this.showResponseModal}
        />
        <OrderModal
          show={showOrderModal}
          selectedSubsidiaries={selectedSubsidiaries}
          areas={areas}
          client={selectedClient}
          user={user}
          weekpart={weekpart}
          product={selectedProduct}
          price={price}
          getPrice={this.getPrice}
          showModal={this.showOrderModal}
          submitOrder={this.submitOfferRequest}
        />
        <LayoutPreviewModal
          ref={this.layoutPreviewModalRef}
          approveLayout={this.approveLayout}
          show={showLayoutPreviewModal}
          showModal={this.showLayoutPreviewModal}
        />
        <ContinueModal
          show={showContinueModal}
          showModal={this.showContinueModal}
          advanceStep={this.advanceStep}
        />
        <HeaderContainer
          user={user}
          clients={clients}
          selectedClient={selectedClient}
          changeSelectedClient={this.changeSelectedClient}
          showLoginModal={this.showLoginModal}
          showChangePassword={this.showChangePasswordModal}
          logoutUser={this.logoutUser}
          advanceStep={this.advanceStep}
        />
        <div className="app-content-container">
          {/* <div className="h-100"> */}
          <StepWizard
            ref={this.stepWizardRef}
            initStep={
              selectedClient?.planLayout ? STEP_INIT : STEP_DISTRIBUTION
            }
            finishButtonText={BUTTON_TITLE_SEND_REQUEST(
              selectedClient && selectedClient.transmissionType === 'ORDER'
            )}
            // hooks={stepWizardHooks}
          >
            <StepSelectionContainer advanceStep={this.advanceStep} />
            <FormatSelectionContainer
              ref={this.formatSelectionRef}
              client={selectedClient}
              setSelectedPrintTemplate={this.setSelectedPrintTemplate}
            />
            <LayoutDesignContainer
              ref={this.layoutDesignContainerRef}
              client={selectedClient}
              selectedPrintTemplate={selectedPrintTemplate}
              enableLoadingOverlay={this.enableLoadingOverlay}
              showLayoutPreviewModal={this.showLayoutPreviewModal}
            />
            <DistributionPlaningContainer
              iFrameURL={iFrameURL}
              user={user}
              clients={clients}
              selectedClient={selectedClient}
              selectedProduct={selectedProduct}
              price={price}
              areas={areas}
              totalCirculation={totalCirculation}
              selectedSubsidiaries={selectedSubsidiaries}
              weekpart={weekpart}
              subsidiaryMode={subsidiaryMode}
              showOrderRequestModal={this.showOrderModal}
              setSelectedProduct={this.setSelectedProduct}
            />
          </StepWizard>
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default withCookies(App);
