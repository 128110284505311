import * as React from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';

import {
  LAYOUT_NAME_MODAL_TITLE,
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_CONFIRM,
  LAYOUT_NAME_MODAL_LAYOUTNAME_LABEL,
  LAYOUT_NAME_MODAL_LAYOUTNAME_PLACEHOLDER,
  LAYOUT_NAME_MODAL_LAYOUTNAME_INVALID,
} from '../../constants/labels';

import {
  LayoutNameModalProps,
  LayoutNameModalState,
} from '../../@types/Modal.d';

export default class LayoutNameModal extends React.Component<
  LayoutNameModalProps,
  LayoutNameModalState
> {
  formRef = React.createRef<HTMLFormElement>();

  constructor(props: LayoutNameModalProps) {
    super(props);

    this.state = {
      layoutName: '',
      formValidated: true,
      saveCopy: false,
    };

    this.onHide = this.onHide.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeLayoutName = this.onChangeLayoutName.bind(this);

    this.setSaveCopy = this.setSaveCopy.bind(this);

    this.resetModal = this.resetModal.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
  }

  onHide(): void {
    const { showModal } = this.props;

    showModal(false);
    this.resetModal();
  }

  onClickAbort(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    this.onHide();
  }

  onChangeLayoutName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    event.preventDefault();

    this.setState({ layoutName: event.currentTarget.value ?? '' });
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();

    const formValid = this.checkFormValid();

    this.setState({ formValidated: true }, () => {
      if (formValid) {
        const { saveLayout } = this.props;
        const { layoutName, saveCopy } = this.state;

        saveLayout(layoutName, saveCopy);

        this.onHide();
      }
    });
  }

  setSaveCopy(saveCopy: boolean): void {
    this.setState({ saveCopy });
  }

  resetModal(): void {
    this.setState({ layoutName: '', formValidated: false, saveCopy: false });
  }

  checkFormValid(): boolean {
    const { current } = this.formRef;

    if (current !== null) return current.checkValidity();

    return false;
  }

  render(): JSX.Element {
    const { formValidated, layoutName } = this.state;
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.onHide} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{LAYOUT_NAME_MODAL_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={this.formRef}
            id="layout-name-modal-form"
            validated={formValidated}
            onSubmit={this.onSubmit}
            noValidate
          >
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>{LAYOUT_NAME_MODAL_LAYOUTNAME_LABEL}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="new-password"
                    type="text"
                    value={layoutName}
                    onChange={this.onChangeLayoutName}
                    placeholder={LAYOUT_NAME_MODAL_LAYOUTNAME_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {LAYOUT_NAME_MODAL_LAYOUTNAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              onClick={this.onClickAbort}
            >
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              form="layout-name-modal-form"
              type="submit"
            >
              {BUTTON_TITLE_CONFIRM}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
