import '../../styles/LayoutDesign.scss';

import * as React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { getPrintTemplates } from '../../util/api';
import { PRINT_TEMPLATE_TITLE } from '../../constants/labels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  FormatSelectionContainerProps,
  FormatSelectionContainerState,
  FormatSelectionItemProps,
} from '../../@types/LayoutDesign.d';
import { PrintTemplate } from '../../@types/Common.d';

export default class FormatSelectionContainer extends React.Component<
  FormatSelectionContainerProps,
  FormatSelectionContainerState
> {
  constructor(props: FormatSelectionContainerProps) {
    super(props);

    this.state = {
      printTemplates: [],
    };

    this.fetchPrintTemplates = this.fetchPrintTemplates.bind(this);
    this.getSelectedPrintTemplate = this.getSelectedPrintTemplate.bind(this);
    this.selectPrintTemplate = this.selectPrintTemplate.bind(this);
  }

  componentDidMount(): void {
    this.fetchPrintTemplates();
  }

  componentDidUpdate(prevProps: FormatSelectionContainerProps): void {
    const { client } = this.props;

    if (client?.id !== prevProps.client?.id) this.fetchPrintTemplates();
  }

  getSelectedPrintTemplate(): PrintTemplate | undefined {
    const { selectedPrintTemplate } = this.state;

    return selectedPrintTemplate;
  }

  fetchPrintTemplates(): void {
    const { client } = this.props;

    if (!client) return;

    const printTemplates = getPrintTemplates(client.id);

    this.setState({ printTemplates });
  }

  selectPrintTemplate(selectedPrintTemplate?: PrintTemplate): void {
    this.setState({ selectedPrintTemplate }, () => {
      const { setSelectedPrintTemplate } = this.props;
      setSelectedPrintTemplate(selectedPrintTemplate);
    });
  }

  render(): JSX.Element {
    const { printTemplates, selectedPrintTemplate } = this.state;

    return (
      <div className="format-selection-container">
        <div className="format-selection-title">{PRINT_TEMPLATE_TITLE}</div>
        <div className="format-search-container" >
          <InputGroup className="format-search-group">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Vorlage suchen ..."
              aria-describedby="search"
            />
          </InputGroup>
        </div>
        <div className="format-selection-item-container">
          <div className="format-selection-items">
            {printTemplates.map((printTemplate: PrintTemplate) => (
              <FormatSelectionItem
                key={printTemplate.id}
                printTemplate={printTemplate}
                selected={printTemplate.id === selectedPrintTemplate?.id}
                selectPrintTemplate={this.selectPrintTemplate}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const FormatSelectionItem: React.FC<FormatSelectionItemProps> = (
  props: FormatSelectionItemProps
) => {
  const { printTemplate, selected, selectPrintTemplate } = props;

  const thumbnail = require('../../resources/img/image_placeholder.png');

  const onClickPrintTemplate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    selectPrintTemplate(selected ? undefined : printTemplate);
  };

  const onKeyDownPrintTemplate = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.keyCode === 13)
      selectPrintTemplate(selected ? undefined : printTemplate);
  };

  return (
    <div className="p-3">
      <div
        className={`format-selection-item ${selected ? 'selected' : ''}`}
        role="button"
        tabIndex={-1}
        onClick={onClickPrintTemplate}
        onKeyDown={onKeyDownPrintTemplate}
      >
        <div className="template-thumbnail">
          <img src={thumbnail} alt="template-thumbnail" />
        </div>
        <div className="template-name">{printTemplate.name}</div>
      </div>
    </div>
  );
};
