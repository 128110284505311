import { Area } from '../@types/Area.d';
import { ClientLocation } from '../@types/Common.d';

export const sortAreas = (areas: Area[]): Area[] => {
  return areas.sort((areaA: Area, areaB: Area) => {
    if (areaA.areaKey.toLocaleLowerCase() < areaB.areaKey.toLocaleLowerCase())
      return -1;
    if (areaA.areaKey.toLocaleLowerCase() > areaB.areaKey.toLocaleLowerCase())
      return 1;

    return 0;
  });
};

export const sortClientLocations = (
  subsidiaries: ClientLocation[]
): ClientLocation[] => {
  return subsidiaries.sort(
    (subsidiaryA: ClientLocation, subsidiaryB: ClientLocation) => {
      if (
        subsidiaryA.name.toLocaleLowerCase() <
        subsidiaryB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        subsidiaryA.name.toLocaleLowerCase() >
        subsidiaryB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );
};
