/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as React from 'react';

import { Row, Col } from 'react-bootstrap';

import { StepSelectionItemProps } from '../../@types/StepSelection.d';

const StepSelectionItem: React.FC<StepSelectionItemProps> = (
  props: StepSelectionItemProps
) => {
  const { advanceStep, title, targetStep } = props;

  const icon = require(`../../resources/img/icon/FP_Icon_${
    title === 'Fortfahren mit Layoutgestaltung' ? 'Layout' : 'Planung'
  }_grau.png`);

  const onClickItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    advanceStep(targetStep);
  };

  return (
    <Row className="no-gutters h-100 align-items-center" onClick={onClickItem}>
      <Col className="text-center h-100">
        <img src={icon} alt={title} />
      </Col>
    </Row>
  );
};

export default StepSelectionItem;
