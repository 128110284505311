import axios from 'axios';

import {
  parseUser,
  parseClients,
  parseFullClient,
  extractPrices,
  extractClientLayouts,
  extractClientLayout,
} from './responseUtil';

import {
  PATH_LOGIN,
  PATH_CHANGE_PASSWORD,
  PATH_USERCLIENTS,
  PATH_CLIENT_DATA,
  PATH_OFFER_REQUEST,
  PATH_ORDER_REQUEST,
  PATH_CALCULATION_FPP,
  PATH_CLIENT_LAYOUTS_GET,
  PATH_CLIENT_LAYOUT_POST,
  PATH_CLIENT_LAYOUT_PUT,
  PATH_CLIENT_LAYOUT_DELETE,
} from '../constants/network';

import config from '../config';

import {
  RequestPayload,
  Client,
  User,
  Product,
  Weekpart,
  SubsidiarySendFormat,
  PriceResult,
  AdditionalOptions,
  PrintTemplate,
  ClientLayout,
  StateFileState,
} from '../@types/Common.d';
import { DUMMY_PRINT_TEMPLATES } from '../constants/constants';

export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
});

const headers = {
  'X-API-Key-Token': process.env.REACT_APP_FRA_API_KEY,
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export const printError = (error: any): string => {
  // eslint-disable-next-line no-console
  if (config.printToConsole) console.log(error);
  return (error.response || error.request).status;
};

export const getClients = (user: string): Promise<Client[] | string> =>
  axiosClient
    .get(PATH_USERCLIENTS, {
      params: { user },
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => parseClients(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const getLogin = (
  email: string,
  password: string
): Promise<User | string> =>
  axiosClient
    .get(PATH_LOGIN, {
      params: { user: email, password },
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => parseUser(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const putChangePassword = (
  user: string,
  oldPassword: string,
  newPassword: string
): Promise<string | number> =>
  axiosClient
    .put(
      PATH_CHANGE_PASSWORD,
      {},
      {
        params: { user, oldPassword, newPassword },
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        },
      }
    )
    .then(
      response => response.status,
      error => printError(error)
    )
    .catch(error => printError(error));

export const getClientData = (clientId: number): Promise<string | Client> =>
  axiosClient
    .get(PATH_CLIENT_DATA(clientId), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => {
        return parseFullClient(response.data);
      },
      error => printError(error)
    )
    .catch(error => printError(error));

export const postOfferRequest = (
  requestPayload: RequestPayload
): Promise<string | number> =>
  axiosClient
    .post(PATH_OFFER_REQUEST, requestPayload, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => response,
      error => {
        printError(error);
        return error;
      }
    )
    .catch(error => {
      printError(error);
      return error;
    });

export const postOrderRequest = (
  requestPayload: RequestPayload
): Promise<string | number> =>
  axiosClient
    .post(PATH_ORDER_REQUEST, requestPayload, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => response,
      error => {
        printError(error);
        return error;
      }
    )
    .catch(error => {
      printError(error);
      return error;
    });

export const getTotalPrice = (
  locations: SubsidiarySendFormat[],
  weekpart: Weekpart,
  printPerSubsidiary: boolean,
  product?: Product,
  additionalOptions?: AdditionalOptions
): Promise<void | PriceResult> =>
  axiosClient
    .post(
      PATH_CALCULATION_FPP,
      {
        locations,
        productId: product?.id ?? -1,
        weekpart,
        printPerSubsidiary,
        additionalOptions,
      },
      { headers }
    )
    .then(response => extractPrices(response.data) as PriceResult);

export const getPrintTemplates = (clientId: number): PrintTemplate[] =>
  DUMMY_PRINT_TEMPLATES;

export const downloadLayoutProofImages = (
  imageURLs: string[]
): Promise<Blob[]> =>
  axios
    .all(
      imageURLs.map(imageURL =>
        axios.get(imageURL, { responseType: 'arraybuffer' })
      )
    )
    .then(responses => {
      return responses.map(
        response => new Blob([response.data], { type: 'image/jpeg' })
      );
    });

export const getClientLayouts = (
  clientId: number,
  state: StateFileState
): Promise<string | ClientLayout[]> =>
  axiosClient
    .get(PATH_CLIENT_LAYOUTS_GET(clientId), {
      params: { state },
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => extractClientLayouts(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const postClientLayout = (
  clientId: number,
  layout: any
): Promise<string | ClientLayout> =>
  axiosClient
    .post(PATH_CLIENT_LAYOUT_POST(clientId), layout, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => extractClientLayout(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const putClientLayout = (
  clientId: number,
  layout: any
): Promise<string | ClientLayout> =>
  axiosClient
    .put(PATH_CLIENT_LAYOUT_PUT(clientId), layout, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => extractClientLayout(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const deleteClientLayouts = (
  clientId: number,
  layoutId: number
): Promise<string | ClientLayout> =>
  axiosClient
    .delete(PATH_CLIENT_LAYOUT_DELETE(clientId, layoutId), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
    })
    .then(
      response => extractClientLayout(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));
