import '../../../styles/AreaList.scss';

import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import SubsidiaryAreaListItem from './SubsidiaryAreaListItem';

import { sortClientLocations } from '../../../util/sortUtil';

import { SubsidiaryAreaListProps } from '../../../@types/AreaList.d';

const SubsidiaryAreaList: React.FC<SubsidiaryAreaListProps> = (
  props: SubsidiaryAreaListProps
) => {
  const { subsidiaries, weekpart, showAll, summary } = props;

  return (
    <ListGroup className="h-100 selected-subsidiary-list">
      {sortClientLocations(subsidiaries).map(subsidiary => (
        <SubsidiaryAreaListItem
          key={subsidiary.id}
          show={showAll}
          subsidiary={subsidiary}
          weekpart={weekpart}
          summary={summary}
        />
      ))}
    </ListGroup>
  );
};

export default SubsidiaryAreaList;
