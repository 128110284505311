import * as React from 'react';

import { Row, Col } from 'react-bootstrap';

import { ContentContainerProps } from '../../../@types/Modal.d';

const ContentContainer: React.FC<ContentContainerProps> = (
  props: ContentContainerProps
) => {
  const { hasLongText, title, children } = props;

  return (
    <div className="content-container">
      <Row className="no-gutters">
        <Col className={`upper-content ${hasLongText ? 'long-text' : ''}`}>
          {title}
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col className="lower-content">
          {React.Children.map(children, pChild => pChild)}
        </Col>
      </Row>
    </div>
  );
};

export default ContentContainer;
