import * as React from 'react';

import { Modal, Button, Col } from 'react-bootstrap';

import {
  CONTINUE_MODAL_CONTENT,
  CONTINUE_MODAL_TITLE,
  CONTINUE_MODAL_DISTRIBUTION,
  CONTINUE_MODAL_LAYOUT,
} from '../../constants/labels';
import { STEP_DISTRIBUTION, STEP_FORMAT } from '../../constants/constants';

import { ContinueModalProps } from '../../@types/Modal.d';

const ContinueModal: React.FC<ContinueModalProps> = (
  props: ContinueModalProps
) => {
  const { show, showModal, advanceStep } = props;

  const onHide = (): void => {
    showModal(false, undefined, undefined, undefined);
  };

  const onClickDistribution = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    advanceStep(STEP_DISTRIBUTION);
    onHide();
  };

  const onClickLayout = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    advanceStep(STEP_FORMAT);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{CONTINUE_MODAL_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{CONTINUE_MODAL_CONTENT}</Modal.Body>
      <Modal.Footer>
        <Col className="p-0">
          <Button
            className="ci-button modal-footer-button"
            onClick={onClickLayout}
          >
            {CONTINUE_MODAL_LAYOUT}
          </Button>
        </Col>
        <Col className="p-0">
          <Button
            className="ci-button modal-footer-button"
            onClick={onClickDistribution}
          >
            {CONTINUE_MODAL_DISTRIBUTION}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default ContinueModal;
