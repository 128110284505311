import '../../styles/Header.scss';

import * as React from 'react';

import HeaderMenu from './HeaderMenu';
import HeaderLogin from './HeaderLogin';

import { HeaderProps, HeaderState } from '../../@types/Header.d';

export default class HeaderContainer extends React.Component<
  HeaderProps,
  HeaderState
> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const {
      user,
      clients,
      selectedClient,
      changeSelectedClient,
      showLoginModal,
      logoutUser,
      showChangePassword,
      advanceStep,
    } = this.props;

    return (
      <div className="header-container">
        {user ? (
          <HeaderMenu
            user={user}
            clients={clients}
            changeSelectedClient={changeSelectedClient}
            selectedClient={selectedClient}
            logoutUser={logoutUser}
            showChangePassword={showChangePassword}
            advanceStep={advanceStep}
          />
        ) : (
          <HeaderLogin showLoginModal={showLoginModal} />
        )}
      </div>
    );
  }
}
