/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as React from 'react';

import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import {
  HEADER_USER_MENU_OPTION_CHANGE_PASS,
  HEADER_USER_MENU_OPTION_LOGOUT,
  HEADER_CLIENT_LABEL,
  HEADER_CLIENT_PLACEHOLDER,
} from '../../constants/labels';
import { selectPickerTheme, STEP_INIT } from '../../constants/constants';

import { HeaderMenuProps, ClientSelect } from '../../@types/Header.d';

const HeaderMenu: React.FC<HeaderMenuProps> = (props: HeaderMenuProps) => {
  const {
    clients,
    selectedClient,
    changeSelectedClient,
    logoutUser,
    showChangePassword,
    advanceStep,
    user,
  } = props;
  // const avatarSrc = require(`../../resources/img/${
  //   user && user.avatar !== '' ? user.avatar : 'user_placeholder'
  // }.png`);

  const clientSelection: ClientSelect[] =
    clients?.map(client => ({
      label: client.name,
      value: client,
    })) ?? ([] as ClientSelect[]);

  const onClickLogout = (event: any): void => {
    logoutUser();
    event.preventDefault();
  };

  const onClickChangePassword = (event: any): void => {
    showChangePassword(true);
    event.preventDefault();
  };

  const onChangeClientSelect = (selection: any): void => {
    if (selection && selection !== null) {
      changeSelectedClient(selection.value);
    }
  };

  const onClickHomeButton = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    advanceStep(STEP_INIT);
  };

  const selectValue = clientSelection.find(
    client => client.value.id === selectedClient?.id
  );

  return (
    <div className="header-menu-container">
      <div className="user-menu-container">
        {selectedClient?.planLayout && (
          <div className="home-container">
            <FontAwesomeIcon
              icon={faHome}
              className="home-icon"
              onClick={onClickHomeButton}
            />
          </div>
        )}
        {/* <div className="user-avatar">
          <img
            src={avatarSrc}
            alt={selectedClient ? selectedClient.name : 'avatar'}
          />
        </div> */}
        <div className="user-menu">
          <DropdownButton
            id="user-menu-dropdown"
            title={`${user.prename} ${user.lastname}`}
            className="user-menu-dropdown"
            alignRight
          >
            <Dropdown.Item as="button" onClick={onClickChangePassword}>
              {HEADER_USER_MENU_OPTION_CHANGE_PASS}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as="button" onClick={onClickLogout}>
              {HEADER_USER_MENU_OPTION_LOGOUT}
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="client-select-container">
        <div className="client-select-label">{HEADER_CLIENT_LABEL}</div>
        <div className="client-select-content">
          <Form.Group>
            <Select
              blurInputOnSelect
              isSearchable
              hasVaue
              placeholder={HEADER_CLIENT_PLACEHOLDER}
              options={clientSelection}
              value={selectValue}
              onChange={onChangeClientSelect}
              theme={theme => selectPickerTheme(theme)}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
