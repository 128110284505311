import * as React from 'react';

import { EditorBuilder } from '@aurigma/design-editor-iframe/EditorBuilder';
import { Editor } from '@aurigma/design-editor-iframe/Editor';

import { CUSTOMER_CANVAS_URL } from '../../constants/network';

import {
  CustomerCanvasProps,
  CustomerCanvasState,
} from '../../@types/LayoutDesign.d';
import {
  CUSTOMER_CANVAS_CONFIG,
  REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS,
} from '../../constants/constants';

export default class CustomerCanvas extends React.Component<
  CustomerCanvasProps,
  CustomerCanvasState
> {
  iFrameRef = React.createRef<HTMLIFrameElement>();

  constructor(props: CustomerCanvasProps) {
    super(props);

    this.state = {};

    this.buildEditor = this.buildEditor.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.finishProduct = this.finishProduct.bind(this);
    this.resetEditor = this.resetEditor.bind(this);
  }

  componentDidMount(): void {
    this.buildEditor();
  }

  componentDidUpdate(prevProps: CustomerCanvasProps): void {
    const { selectedPrintTemplate, client } = this.props;

    if (
      JSON.stringify(selectedPrintTemplate) !==
        JSON.stringify(prevProps.selectedPrintTemplate) ||
      prevProps.client?.id !== client?.id
    )
      this.buildEditor();
  }

  async getProofImages(): Promise<void | Editor.IProofResult> {
    const { editor } = this.state;

    if (!editor) return;

    // eslint-disable-next-line consistent-return
    return editor
      .getProofImages({ maxHeight: 640, maxWidth: 640 })
      .then(result => result);
  }

  resetEditor(): void {
    this.buildEditor();
  }

  async buildEditor(): Promise<void> {
    const { current } = this.iFrameRef;
    const { selectedPrintTemplate, client, enableLoadingOverlay } = this.props;

    if (current === null || !selectedPrintTemplate || !client) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS);

    const { product } = selectedPrintTemplate;

    const config = CUSTOMER_CANVAS_CONFIG(client.id);

    const editor = (await EditorBuilder.for(CUSTOMER_CANVAS_URL)
      .build(current, product, config)
      .then(
        (nEditor: Editor) => {
          return nEditor;
        },
        () => {}
      )
      .finally(() =>
        enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOAD_CUSTOMER_CANVAS)
      )) as Editor;

    this.setState({
      editor,
    });
  }

  async saveProduct(
    stateId: string
  ): Promise<void | Editor.ISaveProductResult> {
    const { editor } = this.state;

    if (!editor) return;

    // eslint-disable-next-line consistent-return
    return (await editor
      .saveProduct(stateId)
      .then(result => result)) as Editor.ISaveProductResult;
  }

  async finishProduct(): Promise<void | Editor.IFinishDesignResult> {
    const { editor } = this.state;

    if (!editor) return;

    // eslint-disable-next-line consistent-return
    return (await editor
      .finishProductDesign()
      .then(result => result)) as Editor.IFinishDesignResult;
  }

  render(): JSX.Element {
    return (
      <div className="customer-canvas">
        <iframe
          ref={this.iFrameRef}
          id="customer-canvas"
          title="Customer Canvas"
          className="customer-canvas-iframe"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }
}
