import {
  ClientLocation,
  AreaSendFormat,
  LocalitySendFormat,
  SubsidiarySendFormat,
  MasterFile,
  ExtraCopy,
  LayoutSelection,
} from '../@types/Common.d';
import { Area, Locality } from '../@types/Area.d';

export const getBas64FromFile = (file?: File): Promise<any> | undefined => {
  if (!file) return;
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  }).then(
    result => result as string,
    error => error
  );
};

export const getLocalitySend = (locality: Locality): LocalitySendFormat =>
  ({
    id: locality.id,
    localityKey: locality.localityKey,
    selected: locality.selected,
  } as LocalitySendFormat);

export const getLocalitiesSend = (
  localities: Locality[]
): LocalitySendFormat[] =>
  localities.map(locality => getLocalitySend(locality)) as LocalitySendFormat[];

export const getAreaSend = (area: Area): AreaSendFormat =>
  ({
    areaKey: area.areaKey,
    countryCode: area.countryCode,
    type: area.type,
    localities: getLocalitiesSend(area.localities),
  } as AreaSendFormat);

export const getAreasSend = (areas: Area[]): AreaSendFormat[] =>
  areas.map(area => getAreaSend(area)) as AreaSendFormat[];

export const getSubsidiariesSend = (
  subsidiaries: ClientLocation[],
  masterFiles: (MasterFile | LayoutSelection)[],
  extraCopies: ExtraCopy[],
  printPerSubsidiary: boolean
): Promise<SubsidiarySendFormat>[] =>
  subsidiaries.map(async subsidiary => {
    let masterFile;

    const match = masterFiles.find(file => file.subsidiaryId === subsidiary.id);

    if (printPerSubsidiary && (match as MasterFile)?.masterFile)
      masterFile = (await getBas64FromFile(
        (match as MasterFile)?.masterFile
      )) as string;
    else if ((match as LayoutSelection)?.layoutId)
      masterFile = `${(match as LayoutSelection).layoutId}`;

    return {
      id: subsidiary.id,
      areas: getAreasSend(subsidiary.areas),
      masterFile: await masterFile,
      extraCopies: extraCopies.find(
        extraCopy => extraCopy.subsidiaryId === subsidiary.id
      )?.extraCopies,
    } as SubsidiarySendFormat;
  });

export const priceString = (price: string): string =>
  parseFloat(price).toLocaleString('en-de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
