import { ClientLocation, Product } from '../@types/Common.d';
import MessageType, {
  MessagePayload,
  Message,
  HideSubsidiarySelectionPayload,
} from '../@types/MessageTypes.d';
import { Area } from '../@types/Area.d';

export const sendMessage = (
  type: MessageType,
  payload: MessagePayload
): void => {
  const pPayload = { type, payload } as Message;

  const mapElement = document.getElementById('map') as HTMLIFrameElement;

  if (mapElement === null || mapElement.contentWindow === null) return;

  mapElement.contentWindow.postMessage(pPayload, '*');
};

export const showLocalitiesModal = (area: Area): void =>
  sendMessage(MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES, area);

export const removeArea = (area: Area): void =>
  sendMessage(MessageType.MESSAGE_TYPE_REMOVE_AREA, area);

export const removeAllAreas = (): void =>
  sendMessage(MessageType.MESSAGE_TYPE_REMOVE_ALL_AREAS, '');

export const removeSubsidiary = (subsidiary: ClientLocation): void =>
  sendMessage(MessageType.MESSAGE_TYPE_REMOVE_SUBSIDIARY, subsidiary);

export const changeProduct = (product: Product): void =>
  sendMessage(MessageType.MESSAGE_TYPE_CHANGE_PRODUCT, product);

export const addOrder = (order: any): void =>
  sendMessage(MessageType.MESSAGE_TYPE_ADD_ORDER, order);

export const updateLocalities = (area: Area): void =>
  sendMessage(MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES_EXTERNAL, {
    ...area,
    ...{ feature: undefined, layer: undefined },
  });

export const hideSubsidiarySelection = (
  subsidiary: ClientLocation,
  show: boolean
): void =>
  sendMessage(MessageType.MESSAGE_TYPE_HIDE_SUBSIDIARY_SELECTION, {
    subsidiaryId: subsidiary.id,
    show,
  } as HideSubsidiarySelectionPayload);
