import * as React from 'react';

import { Form, Row, Col } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';

import moment, { Moment } from 'moment';

import ContentContainer from './ContentContainer';

import {
  BONIAL_EXPLANATION,
  PRICE_CURRENCY_SHORT,
  OFFER_MODAL_EXTRAS_CLIENTLOCATION_TITLE,
  OFFER_MODAL_EXTRAS_RANGE_TITLE,
  OFFER_MODAL_EXTRAS_RANGE_PLACEHOLDER,
  OFFER_MODAL_EXTRAS_DURATION_TITLE,
  OFFER_MODAL_EXTRAS_DURATION_PLACEHOLDER,
  OFFER_MODAL_EXTRAS_WEB_TITLE,
  OFFER_MODAL_EXTRAS_WEB_PLACEHOLDER,
  UNIT_WEEKS,
  UNIT_KILOMETERS_SHORT,
  NO_SUBSIDIARIES_SELECTED,
  OFFER_MODAL_EXTRAS_START_DATE_PLACEHOLDER,
  OFFER_MODAL_EXTRAS_START_DATE_TITLE,
} from '../../../constants/labels';

import {
  AdditionalOptionsContainerProps,
  AdditionalOptionListPorps,
  KaufDaDurationPriceDropOptionProps,
  KaufDaListState,
  KaufDaListItemProps,
} from '../../../@types/Modal.d';
import { KaufDaItem, KaufDaItemSettings } from '../../../@types/Common.d';

import kaufdaLogo from '../../../resources/img/kaufdaLogo.svg';
import { selectPickerTheme } from '../../../constants/constants';

const clientLocationWidth = 2;
const startWidth = 2;
const rangeWidth = 2;
const durationWidth = 3;
const webWidth = 3;

const KaufDaDurationPriceSelectOption: React.FC<KaufDaDurationPriceDropOptionProps> = (
  props: KaufDaDurationPriceDropOptionProps
) => {
  const { data } = props;

  return (
    <div className="kauf-da-duration-price-dropdown-option">
      <div className="kauf-da-duration-price-name custom-text-wrap">
        {`${data.duration} ${UNIT_WEEKS}`}
      </div>
      <small className="kauf-da-duration-price-date">{`(${data.price} ${PRICE_CURRENCY_SHORT})`}</small>
    </div>
  );
};

const AdditionalOptionsContainer: React.FC<AdditionalOptionsContainerProps> = (
  props: AdditionalOptionsContainerProps
) => {
  const {
    additionalOptionSettings,
    additionalOptions,
    distributionDate,
    changeKaufDaItem,
  } = props;

  const { kaufDaItems } = additionalOptions;

  const kaufDaSettings = additionalOptionSettings.find(
    item => item.type === 'KAUFDA'
  ) as KaufDaItemSettings;

  return (
    <div>
      {kaufDaItems && kaufDaSettings && (
        <ContentContainer title={BONIAL_EXPLANATION} hasLongText>
          <KaufDaList
            setting={kaufDaSettings}
            items={kaufDaItems}
            distributionDate={distributionDate}
            changeItem={changeKaufDaItem}
          />
        </ContentContainer>
      )}
    </div>
  );
};

export default AdditionalOptionsContainer;

class KaufDaList extends React.Component<
  AdditionalOptionListPorps<KaufDaItemSettings, KaufDaItem>,
  KaufDaListState
> {
  headerRowRef = React.createRef<HTMLDivElement>();

  listRef = React.createRef<HTMLDivElement>();

  constructor(
    props: AdditionalOptionListPorps<KaufDaItemSettings, KaufDaItem>
  ) {
    super(props);

    const { items } = this.props;

    this.state = {
      datePickerFocuses: items.reduce((acc, item) => {
        const rAcc = acc;
        rAcc[item.subsidiaryId] = false;

        return rAcc;
      }, {} as { [key: number]: boolean }),
    };

    this.getListMaxHeight = this.getListMaxHeight.bind(this);

    this.onDatePickerFocusChange = this.onDatePickerFocusChange.bind(this);
  }

  onDatePickerFocusChange(
    id: number,
    startDatePickerFocused: boolean | null
  ): void {
    const { datePickerFocuses } = this.state;

    const nDatePickerFocuses = {
      ...datePickerFocuses,
      ...{
        [id]: startDatePickerFocused !== null ? startDatePickerFocused : false,
      },
    };

    this.setState({ datePickerFocuses: nDatePickerFocuses });
  }

  getListMaxHeight(): number | undefined {
    if (this.headerRowRef.current !== null && this.listRef.current !== null)
      return (
        this.headerRowRef.current.clientHeight +
        this.listRef.current.clientHeight
      );

    return undefined;
  }

  render(): JSX.Element {
    const { setting, items, distributionDate, changeItem } = this.props;
    const { datePickerFocuses } = this.state;

    return (
      <div className="kauf-da-container">
        <div className="additional-option-selection-container">
          <img
            className="additional-option-logo"
            src={kaufdaLogo}
            alt="kauf-da-logo"
          />
        </div>
        <div className="separator" />
        <div className="additional-option-list-container">
          {items.length > 0 ? (
            <>
              <Row
                ref={this.headerRowRef}
                className="no-gutters additoinal-item-table-header"
              >
                <Col
                  className="additoinal-item-table-header-item custom-text-wrap"
                  xs={clientLocationWidth}
                >
                  {OFFER_MODAL_EXTRAS_CLIENTLOCATION_TITLE}
                </Col>
                <Col
                  className="additoinal-item-table-header-item custom-text-wrap"
                  xs={startWidth}
                >
                  {OFFER_MODAL_EXTRAS_START_DATE_TITLE}
                </Col>
                <Col
                  className="additoinal-item-table-header-item custom-text-wrap"
                  xs={rangeWidth}
                >
                  {OFFER_MODAL_EXTRAS_RANGE_TITLE}
                </Col>
                <Col
                  className="additoinal-item-table-header-item custom-text-wrap"
                  xs={durationWidth}
                >
                  {OFFER_MODAL_EXTRAS_DURATION_TITLE}
                </Col>
                <Col
                  className="additoinal-item-table-header-item custom-text-wrap"
                  xs={webWidth}
                >
                  {OFFER_MODAL_EXTRAS_WEB_TITLE}
                </Col>
              </Row>
              <div ref={this.listRef}>
                {items.map(item => (
                  <KaufDaListItem
                    key={item.subsidiaryId}
                    setting={setting}
                    item={item}
                    distributionDate={distributionDate}
                    datePickerFocused={datePickerFocuses[item.subsidiaryId]}
                    changeItem={changeItem}
                    onDatePickerFocusChange={this.onDatePickerFocusChange}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="empty-list-container">
              {NO_SUBSIDIARIES_SELECTED}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const KaufDaListItem: React.FC<KaufDaListItemProps<
  KaufDaItemSettings,
  KaufDaItem
>> = (props: KaufDaListItemProps<KaufDaItemSettings, KaufDaItem>) => {
  const {
    item,
    setting,
    datePickerFocused,
    changeItem,
    onDatePickerFocusChange,
  } = props;
  const { presetSelectionDurations, presetSelectionRanges } = setting;
  const { duration, range } = item;

  const onChangeItem = (property: keyof KaufDaItem, value: any): void => {
    changeItem(property, value, item.subsidiaryId);
  };

  const onChangeStartDate = (pStartDate: Moment | null): void => {
    if (pStartDate && pStartDate !== null)
      changeItem('startDate', pStartDate, item.subsidiaryId);
  };

  const isDayHighlighted = (day: Moment): boolean =>
    day.unix() === item.startDate.unix();

  const isDayBlocked = (day: Moment): boolean =>
    day.isBefore(moment().add(3, 'days'));

  return (
    <Row className="no-gutters">
      <Col
        xs={clientLocationWidth}
        className="additional-item-select additional-item-col"
      >
        <Form.Check
          type="switch"
          id={`kauf-da-${item.subsidiaryId}-switch`}
          label={item.subsidiaryName}
          checked={item.selected}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeItem('selected', event.currentTarget.checked)
          }
        />
      </Col>
      <Col
        xs={startWidth}
        className="additional-item-select additional-item-col"
      >
        <SingleDatePicker
          date={item.startDate}
          onDateChange={onChangeStartDate}
          focused={datePickerFocused}
          onFocusChange={({ focused }) =>
            onDatePickerFocusChange(
              item.subsidiaryId,
              focused !== null ? focused : false
            )
          }
          id={`start-date-picker-${item.subsidiaryId}`}
          placeholder={OFFER_MODAL_EXTRAS_START_DATE_PLACEHOLDER}
          showClearDate
          isDayBlocked={(day: any) => isDayBlocked(day as Moment)}
          isDayHighlighted={(day: any) => isDayHighlighted(day as Moment)}
          numberOfMonths={1}
          displayFormat="DD.MM.YYYY"
          disabled={!item.selected}
          block
        />
      </Col>
      <Col xs={rangeWidth} className="additional-item-col">
        <Select
          className="additional-item-drop"
          isSearchable={false}
          options={presetSelectionRanges.map(pRange => ({
            value: pRange,
            label: `${pRange} ${UNIT_KILOMETERS_SHORT}`,
          }))}
          value={
            range
              ? {
                  value: range,
                  label: `${range} ${UNIT_KILOMETERS_SHORT}`,
                }
              : undefined
          }
          placeholder={OFFER_MODAL_EXTRAS_RANGE_PLACEHOLDER}
          onChange={(value: any) => {
            if (value && value !== null) onChangeItem('range', value.value);
          }}
          theme={(theme: any) => selectPickerTheme(theme)}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={false}
          isDisabled={!item.selected}
        />
      </Col>
      <Col xs={durationWidth} className="additional-item-col">
        <Select
          className="additional-item-drop"
          isSearchable={false}
          options={presetSelectionDurations.map(pDuration => ({
            value: pDuration.id,
            label: pDuration.duration,
            data: pDuration,
          }))}
          value={
            duration
              ? {
                  value: duration.id,
                  label: duration.duration,
                  data: duration,
                }
              : undefined
          }
          placeholder={OFFER_MODAL_EXTRAS_DURATION_PLACEHOLDER}
          onChange={(value: any) => {
            if (value && value !== null) onChangeItem('duration', value.data);
          }}
          theme={(theme: any) => selectPickerTheme(theme)}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          }}
          formatOptionLabel={KaufDaDurationPriceSelectOption}
          menuPortalTarget={document.body}
          closeMenuOnScroll={false}
          isDisabled={!item.selected}
        />
      </Col>
      <Col xs={webWidth} className="additional-item-col">
        <Form.Control
          as="input"
          autoComplete="new-password"
          placeholder={OFFER_MODAL_EXTRAS_WEB_PLACEHOLDER}
          type="text"
          value={item.alternateHomepage}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeItem('alternateHomepage', event.currentTarget.value)
          }
          disabled={!item.selected}
        />
      </Col>
    </Row>
  );
};
