import * as React from 'react';

import { Modal } from 'react-bootstrap';

import { Editor } from '@aurigma/design-editor-iframe/Editor';
import {
  LAYOUT_PREVIEW_MODAL_TITLE_PREVIEW,
  LAYOUT_PREVIEW_MODAL_TITLE_APPROVE,
  BUTTON_TITLE_CLOSE,
  BUTTON_TITLE_APPROVE,
} from '../../constants/labels';

import {
  LayoutPreviewModalProps,
  LayoutPreviewModalState,
} from '../../@types/Modal.d';
import StepWizard from '../Common/StepWizard';

export default class LayoutPreviewModal extends React.Component<
  LayoutPreviewModalProps,
  LayoutPreviewModalState
> {
  constructor(props: LayoutPreviewModalProps) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.setLayout = this.setLayout.bind(this);
  }

  onHide(): void {
    const { showModal } = this.props;
    showModal(false);
  }

  async onClickConfirm(): Promise<void> {
    const { approveLayout } = this.props;

    approveLayout();
    this.onHide();
  }

  setLayout(proofResult?: Editor.IProofResult, isPreview?: boolean): void {
    this.setState({
      isPreview,
      proofResult,
    });
  }

  render(): JSX.Element {
    const { show } = this.props;
    const { proofResult, isPreview } = this.state;

    let images = [] as string[];

    if (proofResult) {
      const { proofImageUrls } = proofResult;
      images = proofImageUrls
        ?.map(proofImage => proofImage.map(image => image))
        .flat();
    }

    return (
      <Modal show={show} onHide={this.onHide} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {isPreview
              ? LAYOUT_PREVIEW_MODAL_TITLE_APPROVE
              : LAYOUT_PREVIEW_MODAL_TITLE_PREVIEW}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="layout-preview-modal-body">
          <StepWizard
            titles={[]}
            abort={this.onHide}
            hooks={{
              [(images?.length ?? 0) - 1]: !isPreview
                ? this.onClickConfirm
                : this.onHide,
            }}
            buttonMode="bottom"
            finishButtonText={
              isPreview ? BUTTON_TITLE_CLOSE : BUTTON_TITLE_APPROVE
            }
          >
            {images?.map((image, index) => (
              <img key={index} src={image} alt={`proofimage-page-${index}`} />
            ))}
          </StepWizard>
        </Modal.Body>
      </Modal>
    );
  }
}
