/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';

import { Row, Col, Table } from 'react-bootstrap';

import ContentContainer from './ContentContainer';

import {
  OFFER_MODAL_SUMMARY_CONACT_TITLE,
  OFFER_MODAL_SUMMARY_BILLING_SUBSIDIARY_TITLE,
  OFFER_MODAL_SUMMARY_BILLING_PER_SUBSIDIARY_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_PER_1000_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION,
  OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL,
  NO_PHONE_NUMBER,
  NO_AREAS_SELECTED,
  OFFER_MODAL_SUMMARY_ORDER_DATA_SUBSIDIARY_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_SUBSIDIARY_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_START_DATE_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_TITLE,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL,
  OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_ON_REQUEST_LABEL,
} from '../../../constants/labels';

import {
  SummaryProps,
  SubsidiaryTableProps,
  AreaTableProps,
  KaufDaTableProps,
} from '../../../@types/Modal.d';
import {
  getTotalCirculationAreas,
  getTotalCirculationSubsidiaries,
} from '../../../util/areaUtil';

import kaufdaLogo from '../../../resources/img/kaufdaLogo.svg';

const TableSeparator: React.FC<{}> = () => (
  <tr>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
    <td className="p-0">
      <div className="separator m-0" />
    </td>
  </tr>
);

const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const {
    client,
    prename,
    lastname,
    salutation,
    email,
    phone,
    areas,
    selectedSubsidiaries,
    billingSubsidiary,
    price,
    extraCopies,
    billingType,
    product,
    additionalOptions,
    user,
  } = props;

  let billingName;
  let billingStreet;
  let billingCity;

  if (billingType === 'CLIENT' && client?.billingAddress) {
    const {
      postcode,
      city,
      street,
      housenumber,
      name,
    } = client?.billingAddress;

    billingName = name;
    billingStreet = `${street} ${housenumber}`;
    billingCity = `${postcode} ${city}`;
  } else if (billingType === 'LOCATION' && billingSubsidiary) {
    const { postcode, city, street, housenumber, name } = billingSubsidiary;

    billingName = name;
    billingStreet = `${street} ${housenumber}`;
    billingCity = `${postcode} ${city}`;
  }

  return (
    <div>
      <Row className="no-gutters">
        <Col xs={12} xl={6}>
          <ContentContainer title={OFFER_MODAL_SUMMARY_CONACT_TITLE}>
            <div className="summary-item-container">
              <div className="summary-item">{`${salutation ??
                ''} ${prename} ${lastname}`}</div>
              <div className="summary-item">{email}</div>
              <div className="summary-item">{phone || NO_PHONE_NUMBER}</div>
            </div>
          </ContentContainer>
        </Col>
        {user && (
          <Col xs={12} xl={6}>
            <ContentContainer
              title={OFFER_MODAL_SUMMARY_BILLING_SUBSIDIARY_TITLE}
            >
              {billingType !== 'PER_LOCATION' ? (
                <div className="summary-item-container">
                  <div className="summary-item">{billingName}</div>
                  <div className="summary-item">{billingStreet}</div>
                  <div className="summary-item">{billingCity}</div>
                </div>
              ) : (
                <div className="summary-item-container">
                  <div className="summary-item">
                    {OFFER_MODAL_SUMMARY_BILLING_PER_SUBSIDIARY_TITLE}
                  </div>
                </div>
              )}
            </ContentContainer>
          </Col>
        )}
      </Row>
      <Row className="no-gutters">
        <Col>
          <ContentContainer title={OFFER_MODAL_SUMMARY_ORDER_DATA_TITLE}>
            <div className="summary-item-container">
              <div className="summary-item sub-section-title">
                {product.name}
              </div>
            </div>
            <div className="separator" />
            <Table size="sm" className="summary-table" borderless>
              {(selectedSubsidiaries?.length ?? 0) > 0 && (
                <SubsidiaryTable
                  selectedSubsidiaries={selectedSubsidiaries}
                  extraCopies={extraCopies}
                  price={price}
                />
              )}
              {(areas?.length ?? 0) > 0 && (
                <AreaTable
                  areas={areas}
                  extraCopies={extraCopies}
                  price={price}
                />
              )}
              {(areas?.length ?? 0) === 0 &&
                (selectedSubsidiaries?.length ?? 0) === 0 && (
                  <div className="empty-list-container">
                    {NO_AREAS_SELECTED}
                  </div>
                )}
              {additionalOptions.kaufDaItems?.some(item => item.selected) ? (
                <>
                  <tbody>
                    <tr>
                      <td className="logo-container">
                        <img
                          className="additional-option-logo"
                          src={kaufdaLogo}
                          alt="kauf-da-logo"
                        />
                      </td>
                    </tr>
                    <TableSeparator />
                  </tbody>
                  <KaufDaTable
                    kaufDaItems={additionalOptions.kaufDaItems}
                    selectedSubsidiaries={selectedSubsidiaries}
                    price={price}
                  />
                </>
              ) : (
                <></>
              )}
              {(selectedSubsidiaries?.length ?? 0) > 0 && (
                <tbody>
                  <tr>
                    <td className="pt-2" />
                  </tr>
                  <TableSeparator />
                  <TableSeparator />
                  <TableSeparator />
                  <tr>
                    <td className="total label pt-2">
                      {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL}
                    </td>
                    <td />
                    <td />
                    <td className="total">
                      {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL(
                        price?.total
                      )}
                    </td>
                    <td className="text-left">
                      <small>
                        {
                          OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION
                        }
                      </small>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </ContentContainer>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;

const SubsidiaryTable: React.FC<SubsidiaryTableProps> = (
  props: SubsidiaryTableProps
) => {
  const { selectedSubsidiaries, extraCopies, price } = props;

  return (
    <>
      <thead>
        <tr>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_SUBSIDIARY_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {selectedSubsidiaries?.map(subsidiary => (
          <tr key={subsidiary.id}>
            <td className="text-left">{subsidiary.name}</td>
            <td>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL(
                extraCopies?.find(copy => copy.subsidiaryId === subsidiary.id)
                  ?.extraCopies
              )}
            </td>
            <td>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL(
                subsidiary.price?.circulation
              )}
            </td>
            <td>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL(
                subsidiary.price?.total
              )}
            </td>
            <td className="text-left">
              <small>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_PER_1000_LABEL(
                  subsidiary.price?.totalPerThousand
                )}
              </small>
            </td>
          </tr>
        ))}
        <TableSeparator />
        <tr>
          <td className="total label">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL}
          </td>
          <td>
            {OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL(
              extraCopies?.reduce((acc, copy) => acc + copy.extraCopies, 0)
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL(
              getTotalCirculationSubsidiaries(selectedSubsidiaries)
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL(
              price?.subtotalDistribution
            )}
          </td>
          <td className="text-left">
            <small>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION}
            </small>
          </td>
        </tr>
      </tbody>
    </>
  );
};

const AreaTable: React.FC<AreaTableProps> = (props: AreaTableProps) => {
  const { areas, extraCopies } = props;

  return (
    <>
      <thead>
        <tr>
          <th />
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <TableSeparator />
        <tr>
          <td className="total label">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL(
              extraCopies?.find(copy => copy.subsidiaryId === -1)?.extraCopies
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL(
              getTotalCirculationAreas(areas)
            )}
          </td>
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_ON_REQUEST_LABEL}
          </td>
          <td />
        </tr>
      </tbody>
    </>
  );
};

const KaufDaTable: React.FC<KaufDaTableProps> = (props: KaufDaTableProps) => {
  const { kaufDaItems, selectedSubsidiaries, price } = props;

  return (
    <>
      <thead>
        <tr>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_SUBSIDIARY_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_TITLE}</th>
          <th>{OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_TITLE}</th>
          <th className="pl-0">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_START_DATE_TITLE}
          </th>
        </tr>
      </thead>
      <tbody>
        {kaufDaItems
          ?.filter(kaufDaItem => kaufDaItem.selected)
          .map(kaufDaItem => (
            <tr key={kaufDaItem.subsidiaryId}>
              <td className="text-left">
                {selectedSubsidiaries?.find(
                  subsidiary => subsidiary.id === kaufDaItem.subsidiaryId
                )?.name ?? 'Keine'}
              </td>
              <td>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_LABEL(
                  kaufDaItem.range
                )}
              </td>
              <td>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_LABEL(
                  kaufDaItem.duration?.duration
                )}
              </td>
              <td>
                {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL(
                  selectedSubsidiaries?.find(
                    subsidiary => subsidiary.id === kaufDaItem.subsidiaryId
                  )?.price?.totalKaufDa
                )}
              </td>
              <td className="text-left">
                {kaufDaItem.startDate.format('DD.MM.YYYY')}
              </td>
            </tr>
          )) ?? <></>}
        <TableSeparator />
        <tr>
          <td className="total label">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL}
          </td>
          <td />
          <td />
          <td className="total">
            {OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL(
              price?.subtotalKaufDa
            )}
          </td>
          <td className="text-left">
            <small>
              {OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION}
            </small>
          </td>
        </tr>
      </tbody>
    </>
  );
};
