import * as React from 'react';
import 'react-dates/initialize';

import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import ContentContainer from './ContentContainer';

import {
  OFFER_MODAL_ADDRESS_TITLE,
  OFFER_MODAL_ADDRESS_CONTACT_PERSON_TITLE,
  OFFER_MODAL_ADDRESS_BILLING_TYPE_LABEL,
  OFFER_MODAL_ADDRESS_COMPANY_LABEL,
  OFFER_MODAL_ADDRESS_COMPANY_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_LABEL,
  OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_INVALID,
  OFFER_MODAL_ADDRESS_SALUTATION_LABEL,
  OFFER_MODAL_ADDRESS_SALUTATION_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_SALUTATION_INVALID,
  OFFER_MODAL_ADDRESS_PRENAME_LABEL,
  OFFER_MODAL_ADDRESS_PRENAME_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_PRENAME_INVALID,
  OFFER_MODAL_ADDRESS_LASTNAME_LABEL,
  OFFER_MODAL_ADDRESS_LASTNAME_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_LASTNAME_INVALID,
  OFFER_MODAL_ADDRESS_PHONE_LABEL,
  OFFER_MODAL_ADDRESS_PHONE_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_PHONE_INVALID,
  OFFER_MODAL_ADDRESS_EMAIL_LABEL,
  OFFER_MODAL_ADDRESS_EMAIL_PLACEHOLDER,
  OFFER_MODAL_ADDRESS_EMAIL_INVALID,
  OFFER_MODAL_ADDRESS_COMPANY_INVALID,
} from '../../../constants/labels';
import {
  selectPickerTheme,
  SALUTATION_ARRAY,
} from '../../../constants/constants';

import { ContactProps } from '../../../@types/Modal.d';
import { Salutation, ClientLocation } from '../../../@types/Common.d';

const Contact: React.FC<ContactProps> = (props: ContactProps) => {
  const {
    user,
    client,
    clientLocations,
    billingSubsidiary,
    billingType,
    company,
    salutation,
    prename,
    lastname,
    phone,
    email,
    changeBillingType,
    changeBillingSubisdiary,
    changeCompany,
    changeSalutation,
    changePrename,
    changeLastname,
    changePhone,
    changeEmail,
  } = props;

  const onChangeBillingType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changeBillingType(
      event.currentTarget.checked ? 'PER_LOCATION' : 'LOCATION'
    );
  };

  const onChangeBillingSubisdiary = (pBillingSubsidiary: any): void => {
    if (pBillingSubsidiary && pBillingSubsidiary !== null)
      changeBillingSubisdiary(pBillingSubsidiary.data as ClientLocation);
  };

  const onChangeSalutation = (pSalutation: any): void => {
    if (pSalutation && pSalutation !== null)
      changeSalutation(pSalutation.value as Salutation);
  };

  const onChangePrename = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changePrename(event.currentTarget.value);
  };

  const onChangeLastname = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changeLastname(event.currentTarget.value);
  };

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>): void => {
    changePhone(event.currentTarget.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    changeEmail(event.currentTarget.value);
  };

  const onChangeCompany = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changeCompany(event.currentTarget.value);
  };

  return (
    <div>
      <ContentContainer title={OFFER_MODAL_ADDRESS_TITLE}>
        {client && billingType !== 'CLIENT' && (
          <>
            <Form.Row>
              <Col xs={12} xl={6} className="switch-container">
                <Form.Check
                  type="switch"
                  id="billing-subsidiary-switch"
                  label=""
                  checked={billingType === 'PER_LOCATION'}
                  onChange={onChangeBillingType}
                />
                <div className="sub-section-title">
                  {OFFER_MODAL_ADDRESS_BILLING_TYPE_LABEL}
                </div>
              </Col>
            </Form.Row>
            <Form.Row
              className={`collapse-content height ${
                billingType === 'LOCATION' ? 'shown' : 'hidden'
              }`}
            >
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>
                    {OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_LABEL}
                  </Form.Label>
                  <Select
                    // blurInputOnSelect
                    isSearchable={false}
                    options={clientLocations.map(pClientLocation => ({
                      value: pClientLocation.id,
                      label: pClientLocation.name,
                      data: pClientLocation,
                    }))}
                    value={
                      billingSubsidiary
                        ? {
                            label: billingSubsidiary?.name ?? '',
                            value: billingSubsidiary?.id,
                            data: billingSubsidiary,
                          }
                        : undefined
                    }
                    placeholder={
                      OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_PLACEHOLDER
                    }
                    onChange={onChangeBillingSubisdiary}
                    theme={(theme: any) => selectPickerTheme(theme)}
                    styles={{
                      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    closeMenuOnScroll={false}
                  />
                  <Form.Control
                    value={
                      billingSubsidiary && billingSubsidiary !== null
                        ? billingSubsidiary.id
                        : undefined
                    }
                    onChange={() => {}}
                    hidden
                    required={billingType === 'LOCATION'}
                  />
                  <Form.Control.Feedback type="invalid">
                    {OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <div className="separator" />
          </>
        )}
        <div className="sub-section-title">
          {OFFER_MODAL_ADDRESS_CONTACT_PERSON_TITLE}
        </div>
        <div className="separator" />
        {!user && (
          <Form.Row>
            <Col xs={12} xl={6}>
              <Form.Group>
                <Form.Label>{OFFER_MODAL_ADDRESS_COMPANY_LABEL}</Form.Label>
                <Form.Control
                  as="input"
                  autoComplete="organization"
                  type="text"
                  value={company}
                  onChange={onChangeCompany}
                  placeholder={OFFER_MODAL_ADDRESS_COMPANY_PLACEHOLDER}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {OFFER_MODAL_ADDRESS_COMPANY_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
        )}
        <Form.Row>
          <Col xs={12} xl={6}>
            <Form.Group>
              <Form.Label>{OFFER_MODAL_ADDRESS_SALUTATION_LABEL} </Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={SALUTATION_ARRAY}
                value={SALUTATION_ARRAY.find(
                  pSalutation => pSalutation.value === salutation
                )}
                placeholder={OFFER_MODAL_ADDRESS_SALUTATION_PLACEHOLDER}
                onChange={onChangeSalutation}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control
                value={
                  salutation && salutation !== null ? salutation : undefined
                }
                onChange={() => {}}
                hidden
                required
              />
              <Form.Control.Feedback type="invalid">
                {OFFER_MODAL_ADDRESS_SALUTATION_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs={12} xl={6}>
            <Form.Group>
              <Form.Label>{OFFER_MODAL_ADDRESS_PRENAME_LABEL}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="given-name"
                type="text"
                value={prename}
                onChange={onChangePrename}
                placeholder={OFFER_MODAL_ADDRESS_PRENAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {OFFER_MODAL_ADDRESS_PRENAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Group>
              <Form.Label>{OFFER_MODAL_ADDRESS_LASTNAME_LABEL}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="family-name"
                type="text"
                value={lastname}
                onChange={onChangeLastname}
                placeholder={OFFER_MODAL_ADDRESS_LASTNAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {OFFER_MODAL_ADDRESS_LASTNAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs={12} xl={6}>
            <Form.Group>
              <Form.Label>{OFFER_MODAL_ADDRESS_EMAIL_LABEL}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="email"
                type="text"
                value={email}
                onChange={onChangeEmail}
                placeholder={OFFER_MODAL_ADDRESS_EMAIL_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {OFFER_MODAL_ADDRESS_EMAIL_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={6}>
            <Form.Group>
              <Form.Label>{OFFER_MODAL_ADDRESS_PHONE_LABEL}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="tel"
                type="text"
                value={phone}
                onChange={onChangePhone}
                placeholder={OFFER_MODAL_ADDRESS_PHONE_PLACEHOLDER}
              />
              <Form.Control.Feedback type="invalid">
                {OFFER_MODAL_ADDRESS_PHONE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </ContentContainer>
    </div>
  );
};

export default Contact;
