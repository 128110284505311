import * as React from 'react';

import { Modal, Form } from 'react-bootstrap';

import moment, { Moment } from 'moment';

import StepWizard from '../../Common/StepWizard';
import Contact from './Contact';
import Summary from './Summary';
import Message from '../../Common/Message';
import PrintData from './PrintData';
import Send from './Send';
import AdditionalOptionsContainer from './AdditionalOptionsContainer';

import { getAllSubsidiaryAreas } from '../../../util/areaUtil';

import {
  OFFER_MODAL_TITLE,
  OFFER_MODAL_STEPS_TITLES,
  WARNING_NO_AREAS_SELECTED_CONTENT,
  WARNING_NO_AREAS_SELECTED_TITLE,
  REQUIRED_FIELD_HINT,
  BUTTON_TITLE_SUBMIT,
} from '../../../constants/labels';

import {
  BillingType,
  ClientLocation,
  Salutation,
  DistributionAppointment,
  MasterFile,
  ExtraCopy,
  AdditionalOptions,
  WarningMessageType,
  KaufDaItem,
  KaufDaDurationPrice,
  ClientLayout,
  LayoutSelection,
} from '../../../@types/Common.d';
import { OrderModalProps, OrderModalState } from '../../../@types/Modal.d';
import { generateAdditionalOptionsStub } from '../../../util/offerOrderUtil';
import Action from './Action';
import { getClientLayouts } from '../../../util/api';

export default class OrderModal extends React.Component<
  OrderModalProps,
  OrderModalState
> {
  formRef = React.createRef<HTMLFormElement>();

  messageRef = React.createRef<Message>();

  constructor(props: OrderModalProps) {
    super(props);

    this.state = this.resetModal();

    this.checkFormValid = this.checkFormValid.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.setFormValidated = this.setFormValidated.bind(this);
    this.getClientLayouts = this.getClientLayouts.bind(this);

    this.changeKaufDaItem = this.changeKaufDaItem.bind(this);
    this.changeActionName = this.changeActionName.bind(this);
    this.changeBillingSubsidiary = this.changeBillingSubsidiary.bind(this);
    this.changeBillingType = this.changeBillingType.bind(this);
    this.changeCompany = this.changeCompany.bind(this);
    this.changeDistirbutionAppointment = this.changeDistirbutionAppointment.bind(
      this
    );
    this.changeDistributionDate = this.changeDistributionDate.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeLastname = this.changeLastname.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changePrename = this.changePrename.bind(this);
    this.changeSalutation = this.changeSalutation.bind(this);
    this.changePrintPerSubsidiary = this.changePrintPerSubsidiary.bind(this);
    this.changeExtraCopiesPerSubsidiary = this.changeExtraCopiesPerSubsidiary.bind(
      this
    );
    this.changeMasterFiles = this.changeMasterFiles.bind(this);
    this.changeExtraCopies = this.changeExtraCopies.bind(this);

    this.changeMessage = this.changeMessage.bind(this);
    this.changePrivacyCheck = this.changePrivacyCheck.bind(this);

    this.isDistributionDayBlocked = this.isDistributionDayBlocked.bind(this);
    this.isDistributionDayHighlighted = this.isDistributionDayHighlighted.bind(
      this
    );
    this.onDistributionDatePickerFocusChange = this.onDistributionDatePickerFocusChange.bind(
      this
    );
    this.onSubmit = this.onSubmit.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  onHide(): void {
    const { showModal } = this.props;

    this.setState(this.resetModal());

    showModal(false);
  }

  onShow(): void {
    this.setState(this.resetModal(), () => {
      const { selectedSubsidiaries, areas, getPrice } = this.props;
      const { printPerSubsidiary, additionalOptions } = this.state;

      if (
        getAllSubsidiaryAreas(selectedSubsidiaries).length <= 0 &&
        (areas?.length ?? 0) <= 0
      ) {
        this.showMessage(
          true,
          WARNING_NO_AREAS_SELECTED_CONTENT,
          WARNING_NO_AREAS_SELECTED_TITLE,
          'warn'
        );
      }

      getPrice(printPerSubsidiary, additionalOptions);

      this.getClientLayouts();
    });
  }

  onDistributionDatePickerFocusChange(
    distributionDatePickerFocused: boolean | null
  ): void {
    this.setState({ distributionDatePickerFocused });
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();

    const formValid = this.checkFormValid();

    this.setState({ formValidated: true }, () => {
      if (formValid) {
        const { submitOrder } = this.props;
        const {
          actionName,
          billingSubsidiary,
          company,
          distributionDate,
          email,
          extraCopies,
          lastname,
          masterFiles,
          message,
          billingType,
          phone,
          prename,
          salutation,
          dateType,
          printPerSubsidiary,
          additionalOptions,
        } = this.state;

        const { kaufDaItems } = additionalOptions;

        const pAdditonalOptions = {
          kaufDaItems: kaufDaItems?.filter(item => item.selected) ?? [],
        } as AdditionalOptions;

        submitOrder(
          actionName,
          distributionDate,
          email,
          extraCopies,
          lastname,
          masterFiles,
          billingType,
          phone,
          prename,
          pAdditonalOptions,
          dateType,
          printPerSubsidiary,
          billingSubsidiary,
          company,
          message,
          salutation
        );

        this.onHide();
      }
    });
  }

  setFormValidated(validated: boolean = true): void {
    this.setState({ formValidated: validated });
  }

  async getClientLayouts(): Promise<void> {
    const { client } = this.props;

    if (!client) return;

    const clientLayouts = (await getClientLayouts(
      client?.id,
      'APPROVED'
    )) as ClientLayout[];

    if (!clientLayouts[0]?.stateFileId) return;

    this.setState({ clientLayouts });
  }

  isDistributionDayHighlighted(day: Moment): boolean {
    const { weekpart } = this.props;
    const { distributionDate } = this.state;

    if (distributionDate === null) return false;

    if (weekpart === 'BEST')
      return (
        distributionDate.isoWeek() === day.isoWeek() &&
        distributionDate.year() === day.year()
      );

    return distributionDate.unix() === day.unix();
  }

  isDistributionDayBlocked(day: Moment): boolean {
    const { weekpart } = this.props;

    if (weekpart === 'BEST') {
      const tenDaysAhead = moment().add(10, 'd');
      return (
        day.isBefore(tenDaysAhead) || day.isoWeek() === tenDaysAhead.isoWeek()
      );
    }

    return (
      day.isBefore(moment().add(9, 'd')) ||
      (day.isoWeekday() !== 3 && weekpart === 'MIDWEEK') ||
      (day.isoWeekday() !== 6 && weekpart === 'WEEKEND')
    );
  }

  changeBillingType(billingType: BillingType): void {
    const { client } = this.props;

    this.setState({
      billingType,
      billingSubsidiary:
        billingType === 'PER_LOCATION'
          ? undefined
          : client?.clientLocations?.find(
              subsidiary => subsidiary.billingDefault
            ),
    });
  }

  changeBillingSubsidiary(billingSubsidiary: ClientLocation): void {
    this.setState({ billingSubsidiary });
  }

  changeSalutation(salutation: Salutation): void {
    this.setState({ salutation });
  }

  changePrename(prename: string): void {
    this.setState({ prename });
  }

  changeLastname(lastname: string): void {
    this.setState({ lastname });
  }

  changePhone(phone: string): void {
    this.setState({ phone });
  }

  changeEmail(email: string): void {
    this.setState({ email });
  }

  changeActionName(actionName: string): void {
    this.setState({ actionName });
  }

  changeCompany(company: string): void {
    this.setState({ company });
  }

  changeDistributionDate(distributionDate: Moment | null): void {
    this.setState({ distributionDate });
  }

  changeDistirbutionAppointment(
    distributionAppointment: DistributionAppointment
  ): void {
    this.setState({
      distributionAppointment,
      actionName: distributionAppointment.name,
      distributionDate: distributionAppointment.date,
      dateType: distributionAppointment.type,
    });
  }

  changePrintPerSubsidiary(printPerSubsidiary: boolean): void {
    this.setState({ printPerSubsidiary, masterFiles: [] }, () => {
      const { getPrice } = this.props;
      const { additionalOptions } = this.state;

      getPrice(printPerSubsidiary, additionalOptions);
    });
  }

  changeExtraCopiesPerSubsidiary(extraCopiesPerSubsidiary: boolean): void {
    this.setState({ extraCopiesPerSubsidiary, extraCopies: [] });
  }

  changeMasterFiles(masterFile: MasterFile | LayoutSelection): void {
    const { masterFiles } = this.state;
    let nMasterFiles = masterFiles;

    const index = masterFiles.findIndex(
      pMasterFile => pMasterFile.subsidiaryId === masterFile.subsidiaryId
    );

    if (index < 0) nMasterFiles = [...masterFiles, ...[masterFile]];
    else nMasterFiles[index] = masterFile;

    this.setState({ masterFiles: nMasterFiles });
  }

  changeExtraCopies(extraCopy: ExtraCopy): void {
    const { extraCopies } = this.state;
    const { selectedSubsidiaries } = this.props;
    let nExtraCopies = extraCopies;

    const index = extraCopies.findIndex(
      pExtraCopy => pExtraCopy.subsidiaryId === extraCopy.subsidiaryId
    );

    if (extraCopy.subsidiaryId === -1) {
      nExtraCopies =
        selectedSubsidiaries && selectedSubsidiaries.length > 0
          ? selectedSubsidiaries.map(
              subsidiary =>
                ({
                  subsidiaryId: subsidiary.id,
                  extraCopies: extraCopy.extraCopies,
                } as ExtraCopy)
            )
          : [
              {
                subsidiaryId: -1,
                extraCopies: extraCopy.extraCopies,
              } as ExtraCopy,
            ];
    } else if (index < 0) nExtraCopies = [...extraCopies, ...[extraCopy]];
    else nExtraCopies[index] = extraCopy;

    this.setState({ extraCopies: nExtraCopies });
  }

  changeMessage(message: string): void {
    this.setState({ message });
  }

  changePrivacyCheck(privacyCheck: boolean): void {
    this.setState({ privacyCheck });
  }

  changeKaufDaItem(
    property: keyof KaufDaItem,
    value: number | string | boolean | KaufDaDurationPrice,
    subsidiaryId: number
  ): void {
    const { additionalOptions } = this.state;
    const { kaufDaItems } = additionalOptions;

    if (!kaufDaItems) return;

    const item = kaufDaItems.find(pItem => pItem.subsidiaryId === subsidiaryId);

    if (!item) return;

    item[property] = value as never;

    this.setState({ additionalOptions }, () => {
      const { getPrice } = this.props;
      const { printPerSubsidiary } = this.state;

      getPrice(printPerSubsidiary, additionalOptions);
    });
  }

  resetModal(): OrderModalState {
    const { client, user, selectedSubsidiaries } = this.props;
    return {
      distributionDatePickerFocused: false,
      isOffer: client?.transmissionType !== 'ORDER',
      formValidated: false,
      billingType: client?.billingType ?? 'PER_LOCATION',
      billingSubsidiary:
        (client?.billingType ?? 'PER_LOCATION') === 'LOCATION'
          ? client?.clientLocations?.find(
              subsidiary => subsidiary.billingDefault
            )
          : undefined,
      salutation: user?.salutation,
      prename: user?.prename ?? '',
      lastname: user?.lastname ?? '',
      phone: '',
      email: user?.email ?? '',
      actionName: '',
      distributionDate: null,
      distributionAppointment: undefined,
      dateType: 'DAY',
      company: '',
      printPerSubsidiary: false,
      extraCopiesPerSubsidiary: false,
      masterFiles: [],
      extraCopies: [],
      message: '',
      privacyCheck: false,
      showMessage: false,
      additionalOptions: generateAdditionalOptionsStub(
        selectedSubsidiaries ?? [],
        client?.additionalOptions
      ),
      clientLayouts: [],
    };
  }

  checkFormValid(): boolean {
    const { current } = this.formRef;
    const { selectedSubsidiaries, areas } = this.props;

    if (current !== null)
      return (
        current.checkValidity() &&
        ((areas?.length ?? 0) > 0 ||
          getAllSubsidiaryAreas(selectedSubsidiaries).length > 0)
      );

    return false;
  }

  showMessage(
    show: boolean,
    content?: string,
    title?: string,
    type?: WarningMessageType
  ): void {
    const { current } = this.messageRef;

    if (current !== null) {
      current.setContents(content, title, type);
      this.setState({ showMessage: show });
    }
  }

  render(): JSX.Element {
    const {
      show,
      client,
      selectedSubsidiaries,
      areas,
      weekpart,
      user,
      product,
      price,
    } = this.props;
    const {
      isOffer,
      formValidated,
      distributionDatePickerFocused,
      billingType,
      salutation,
      prename,
      lastname,
      phone,
      email,
      actionName,
      distributionDate,
      billingSubsidiary,
      distributionAppointment,
      company,
      printPerSubsidiary,
      extraCopiesPerSubsidiary,
      masterFiles,
      extraCopies,
      clientLayouts,
      message,
      privacyCheck,
      showMessage,
      additionalOptions,
    } = this.state;

    return (
      <Modal
        className="order-modal"
        show={show}
        onHide={this.onHide}
        onShow={this.onShow}
        centered
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{OFFER_MODAL_TITLE(isOffer)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="order-modal-body">
          <Message
            ref={this.messageRef}
            show={showMessage}
            closeMessage={this.showMessage}
          />
          <Form
            ref={this.formRef}
            id="order-modal-from"
            validated={formValidated}
            onSubmit={this.onSubmit}
            noValidate
          >
            <StepWizard
              titles={OFFER_MODAL_STEPS_TITLES}
              finishButtonText={BUTTON_TITLE_SUBMIT}
              abort={this.onHide}
              checkFormValid={this.checkFormValid}
              setFormValidated={this.setFormValidated}
              formId="order-modal-from"
              showTitleBar
              buttonMode="bottom"
            >
              <Action
                distributionDateType={client?.distributionDateType ?? 'FREE'}
                distributionAppointment={distributionAppointment}
                isOffer={isOffer}
                weekpart={weekpart}
                datePickerFocused={distributionDatePickerFocused}
                actionName={actionName}
                distributionDate={distributionDate}
                distributionAppointments={
                  client?.distributionAppointments ?? []
                }
                changeActionName={this.changeActionName}
                changeDistributionDate={this.changeDistributionDate}
                changeDistributionAppointment={
                  this.changeDistirbutionAppointment
                }
                onDatePickerFocusChange={
                  this.onDistributionDatePickerFocusChange
                }
                isDayHighlighted={this.isDistributionDayHighlighted}
                isDayBlocked={this.isDistributionDayBlocked}
              />
              {client?.additionalOptions?.some(option => option.enabled) && (
                <AdditionalOptionsContainer
                  additionalOptions={additionalOptions}
                  additionalOptionSettings={client.additionalOptions}
                  distributionDate={distributionDate}
                  changeKaufDaItem={this.changeKaufDaItem}
                />
              )}
              <Contact
                company={company}
                billingType={billingType}
                user={user}
                client={client}
                clientLocations={client?.clientLocations ?? []}
                billingSubsidiary={billingSubsidiary}
                salutation={salutation}
                prename={prename}
                lastname={lastname}
                phone={phone}
                email={email}
                changeBillingType={this.changeBillingType}
                changeBillingSubisdiary={this.changeBillingSubsidiary}
                changeCompany={this.changeCompany}
                changeSalutation={this.changeSalutation}
                changePrename={this.changePrename}
                changeLastname={this.changeLastname}
                changePhone={this.changePhone}
                changeEmail={this.changeEmail}
              />
              {product.printDocRequired && (
                <PrintData
                  client={client}
                  product={product}
                  printPerSubsidiary={printPerSubsidiary}
                  extraCopriesPerSubsidiary={extraCopiesPerSubsidiary}
                  masterFiles={masterFiles}
                  extraCopies={extraCopies}
                  clientLayouts={clientLayouts}
                  selectedSubsidiaries={selectedSubsidiaries ?? []}
                  changePrintPerSubsidiary={this.changePrintPerSubsidiary}
                  changeExtraCopiesPerSubsidiary={
                    this.changeExtraCopiesPerSubsidiary
                  }
                  changeMasterFiles={this.changeMasterFiles}
                  changeExtraCopies={this.changeExtraCopies}
                />
              )}
              <Summary
                client={client}
                user={user}
                areas={areas}
                selectedSubsidiaries={selectedSubsidiaries}
                prename={prename}
                lastname={lastname}
                salutation={salutation}
                email={email}
                phone={phone}
                billingSubsidiary={billingSubsidiary}
                extraCopies={extraCopies}
                billingType={billingType}
                product={product}
                price={price}
                additionalOptions={additionalOptions}
              />
              <Send
                message={message}
                privacyCheck={privacyCheck}
                changeMessage={this.changeMessage}
                changePrivacyCheck={this.changePrivacyCheck}
              />
            </StepWizard>
            <div className="required-hint-footer">
              <div>{REQUIRED_FIELD_HINT}</div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
