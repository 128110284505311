import '../../styles/StepWizard.scss';

import * as React from 'react';

import { Button, Row, Col } from 'react-bootstrap';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_NEXT,
  BUTTON_TITLE_PREVOUS,
  BUTTON_TITLE_FINISH,
} from '../../constants/labels';

import {
  StepWizardProps,
  StepWizardState,
  TitleBarProps,
  StepWizardButtonsProps,
} from '../../@types/App.d';

export default class StepWizard extends React.Component<
  StepWizardProps,
  StepWizardState
> {
  constructor(props: StepWizardProps) {
    super(props);

    const { titles, children, initStep } = this.props;

    this.state = {
      step: initStep ?? 0,
      direction: 'next',
      titles: titles?.filter((title, index) => children[index]) ?? [],
    };

    this.nextStep = this.nextStep.bind(this);
    this.setNextStep = this.setNextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.advanceToStep = this.advanceToStep.bind(this);
    this.abort = this.abort.bind(this);

    this.getCSSAnimationClass = this.getCSSAnimationClass.bind(this);
  }

  getCSSAnimationClass(index: number): string {
    const { step, direction } = this.state;

    if (index < step) return 'hidden-to-left';
    if (index > step) return 'hidden-to-right';

    if (direction === 'previous') return 'show-from-left';

    return 'show-from-right';
  }

  setNextStep(): void {
    const { children, setFormValidated } = this.props;
    const { step } = this.state;

    if (step + 1 < children.filter(child => child).length)
      this.setState({ step: step + 1, direction: 'next' }, () => {
        if (
          step + 1 === children.filter(child => child).length - 1 &&
          setFormValidated
        )
          setFormValidated();
      });
  }

  async nextStep(): Promise<void> {
    const { children, hooks } = this.props;
    const { step } = this.state;

    if (!children) return;

    if (hooks && hooks[step])
      await hooks[step](() => {
        this.setNextStep();
      });
    else this.setNextStep();
  }

  advanceToStep(nextStep: number): void {
    const { children } = this.props;
    const { step } = this.state;

    if (!children) return;

    if (nextStep > step && nextStep < children.length)
      this.setState({ step: nextStep, direction: 'next' });
    else if (nextStep < step && nextStep >= 0)
      this.setState({ step: nextStep, direction: 'previous' });
  }

  previousStep(): void {
    const { step } = this.state;

    if (step - 1 >= 0) this.setState({ step: step - 1, direction: 'previous' });
  }

  abort(): void {
    const { abort } = this.props;

    if (abort) abort();
  }

  render(): JSX.Element {
    const {
      children,
      formId,
      showTitleBar,
      finishButtonText,
      buttonMode,
      abort,
      checkFormValid,
    } = this.props;
    const { step, titles } = this.state;

    const lastStep = step === children.filter(child => child).length - 1;

    return (
      <div className="wizard-container">
        {buttonMode === 'top' && (
          <StepWizardButtons
            step={step}
            lastStep={lastStep}
            formId={formId}
            finishButtonText={finishButtonText}
            abort={abort}
            checkFormValid={checkFormValid}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
          />
        )}
        {showTitleBar ? (
          <TitleBar titles={titles} activeIndex={step} />
        ) : (
          <div className="wizard-title">{titles[step]}</div>
        )}
        <div className="wizard-content">
          {children
            .filter(child => child)
            .map((child, index) =>
              React.Children.map(child, pChild => (
                <div
                  className={`wizard-content-item ${this.getCSSAnimationClass(
                    index
                  )}`}
                >
                  {pChild}
                </div>
              ))
            )}
        </div>
        {buttonMode === 'bottom' && (
          <StepWizardButtons
            step={step}
            lastStep={lastStep}
            formId={formId}
            finishButtonText={finishButtonText}
            abort={abort}
            checkFormValid={checkFormValid}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
          />
        )}
      </div>
    );
  }
}

const StepWizardButtons: React.FC<StepWizardButtonsProps> = (
  props: StepWizardButtonsProps
) => {
  const {
    step,
    lastStep,
    formId,
    finishButtonText,
    abort,
    checkFormValid,
    nextStep,
    previousStep,
  } = props;

  const onClickPrevious = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    previousStep();
  };

  const onClickNext = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    nextStep();
  };

  const onClickAbort = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    if (abort) abort();
  };

  return (
    <div className="wizard-buttons">
      <div className="wizard-button previous">
        <Button
          className="ci-button"
          onClick={onClickPrevious}
          disabled={step === 0}
        >
          {BUTTON_TITLE_PREVOUS}
        </Button>
      </div>
      {abort && (
        <div className="wizard-button abort">
          <Button className="ci-button" onClick={onClickAbort}>
            {BUTTON_TITLE_ABORT}
          </Button>
        </div>
      )}
      <div className="wizard-button next">
        {lastStep && formId ? (
          <Button
            className="ci-button"
            type="submit"
            form={formId}
            disabled={checkFormValid ? !checkFormValid() : false}
          >
            {finishButtonText ?? BUTTON_TITLE_FINISH}
          </Button>
        ) : (
          <Button className="ci-button" onClick={onClickNext}>
            {lastStep
              ? finishButtonText ?? BUTTON_TITLE_FINISH
              : BUTTON_TITLE_NEXT}
          </Button>
        )}
      </div>
    </div>
  );
};

const TitleBar: React.FC<TitleBarProps> = (props: TitleBarProps) => {
  const { titles, activeIndex } = props;

  return (
    <Row className="title-bar">
      {titles.map((title, index) => (
        <Col
          // eslint-disable-next-line react/no-array-index-key
          key={`${title}-${index}`}
          className={`title-bar-item ${index === activeIndex ? 'active' : ''}`}
        >
          {title}
        </Col>
      ))}
    </Row>
  );
};
