import * as React from 'react';

import { Modal, Col, Button } from 'react-bootstrap';

import { BUTTON_TITLE_CONFIRM } from '../../constants/labels';

import { ResponseModalProps, ResponseModalState } from '../../@types/Modal.d';

export default class ResponseModal extends React.Component<
  ResponseModalProps,
  ResponseModalState
> {
  constructor(props: ResponseModalProps) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
  }

  onHide(): void {
    const { showModal } = this.props;

    showModal(false);
  }

  setModalContents(title: string, content: string): void {
    this.setState({ title, content });
  }

  render(): JSX.Element {
    const { show } = this.props;
    const { title, content } = this.state;

    return (
      <Modal show={show} onHide={this.onHide} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              onClick={this.onHide}
            >
              {BUTTON_TITLE_CONFIRM}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
