import '../../styles/LayoutDesign.scss';

import * as React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { Editor } from '@aurigma/design-editor-iframe/Editor';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';

import CustomerCanvas from './CustomerCanvas';

import {
  BUTTON_TITLE_SAVE,
  BUTTON_TITLE_SAVE_AS,
  NO_PRINT_TEMPLATE_SELECTED,
  BUTTON_TITLE_APPROVE,
  BUTTON_TITLE_PREVIEW,
  BUTTON_TITLE_DOWNLOAD,
} from '../../constants/labels';

import {
  LayoutDesignContainerProps,
  LayoutDesignContainerState,
} from '../../@types/LayoutDesign.d';
import {
  downloadLayoutProofImages,
  getClientLayouts,
  postClientLayout,
  putClientLayout,
} from '../../util/api';

import {
  REQUEST_IDENTIFIER_SAVE_LAYOUT,
  REQUEST_IDENTIFIER_GET_PROOF_IMAGES,
  REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW,
} from '../../constants/constants';
import { ClientLayout } from '../../@types/Common.d';
import LayoutNameModal from '../Modal/LayoutNameModal';

export default class LayoutDesignContainer extends React.Component<
  LayoutDesignContainerProps,
  LayoutDesignContainerState
> {
  customerCanvasRef = React.createRef<CustomerCanvas>();

  layoutNameModalRef = React.createRef<LayoutNameModal>();

  constructor(props: LayoutDesignContainerProps) {
    super(props);

    this.state = {
      clientLayouts: [],
      showLayoutNameModal: false,
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onClickSaveAs = this.onClickSaveAs.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);

    this.getClientLayouts = this.getClientLayouts.bind(this);

    this.saveLayout = this.saveLayout.bind(this);
    this.previewLayoutDesign = this.previewLayoutDesign.bind(this);
    this.approveLayout = this.approveLayout.bind(this);
    this.downloadProofImages = this.downloadProofImages.bind(this);
    this.showLayoutNameModal = this.showLayoutNameModal.bind(this);
  }

  componentDidMount(): void {
    this.getClientLayouts();
  }

  componentDidUpdate(prevProps: LayoutDesignContainerProps): void {
    const { client, selectedPrintTemplate } = this.props;

    if (
      client?.id !== prevProps.client?.id ||
      selectedPrintTemplate?.id !== prevProps.selectedPrintTemplate?.id
    )
      this.getClientLayouts();
  }

  async onClickSave(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { selectedClientLayout } = this.state;

    if (!selectedClientLayout) this.showLayoutNameModal(true);
    else this.saveLayout();
  }

  async onClickSaveAs(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { current } = this.layoutNameModalRef;

    if (current === null) return;

    current.setSaveCopy(true);

    this.showLayoutNameModal(true);
  }

  async onClickPreview(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { showLayoutPreviewModal } = this.props;

    const proofImages = await this.previewLayoutDesign();

    if (proofImages) showLayoutPreviewModal(true, proofImages, true);
  }

  async onClickApprove(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const { showLayoutPreviewModal } = this.props;

    const proofImages = await this.previewLayoutDesign();

    if (proofImages) showLayoutPreviewModal(true, proofImages, false);
  }

  async onClickDownload(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const proofImages = await this.previewLayoutDesign();

    if (proofImages)
      this.downloadProofImages(
        proofImages.proofImageUrls
          .map(proofImage => proofImage.map(image => image))
          .flat()
      );
  }

  async getClientLayouts(): Promise<void> {
    const { client } = this.props;

    if (!client) return;

    const clientLayouts = (await getClientLayouts(
      client.id,
      'EDIT'
    )) as ClientLayout[];

    if (clientLayouts[0]?.id) {
      this.setState({ clientLayouts });
    }
  }

  resetState(): void {
    this.setState({
      clientLayouts: [],
      selectedClientLayout: undefined,
      showLayoutNameModal: false,
    });
  }

  async saveLayout(
    layoutName?: string,
    saveCopy?: boolean
  ): Promise<void | ClientLayout> {
    const { enableLoadingOverlay } = this.props;

    const { current } = this.customerCanvasRef;
    const { selectedClientLayout, clientLayouts } = this.state;
    const { client } = this.props;

    if (current === null || !client) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_SAVE_LAYOUT);

    let stateFieldId = selectedClientLayout?.stateFileId;

    if (saveCopy || !stateFieldId)
      stateFieldId = `${moment().format('DD-MM-YYYY-HH_mm_SS')}-${client.id}`;

    const saveResult = await current.saveProduct(stateFieldId);

    if (!saveResult) {
      enableLoadingOverlay(false, REQUEST_IDENTIFIER_SAVE_LAYOUT);
      return;
    }

    let clientLayoutResult;

    if (!selectedClientLayout || saveCopy) {
      clientLayoutResult = (await postClientLayout(client.id, {
        clientId: client.id,
        name:
          layoutName ??
          `${moment().format('DD-MM-YYYY-HH_mm_SS')}-${client.id}`,
        state: 'EDIT',
        stateFileId: saveResult.stateId,
        url: saveResult.returnToEditUrl,
      })) as ClientLayout;
    } else {
      clientLayoutResult = (await putClientLayout(client.id, {
        ...selectedClientLayout,
        ...{
          stateFieldId: saveResult.stateId,
          url: saveResult.returnToEditUrl,
          name: layoutName ?? selectedClientLayout.name,
        },
      })) as ClientLayout;
    }

    if (!clientLayoutResult) {
      enableLoadingOverlay(false, REQUEST_IDENTIFIER_SAVE_LAYOUT);
      return;
    }

    this.setState(
      {
        selectedClientLayout: clientLayoutResult,
        clientLayouts: [
          ...clientLayouts,
          ...[clientLayoutResult],
        ].sort((a: ClientLayout, b: ClientLayout) =>
          a.name > b.name ? 1 : a.name > b.name ? -1 : 0
        ),
      },
      () => enableLoadingOverlay(false, REQUEST_IDENTIFIER_SAVE_LAYOUT)
    );

    return clientLayoutResult;
  }

  async previewLayoutDesign(): Promise<void | Editor.IProofResult> {
    const { enableLoadingOverlay } = this.props;

    const { current } = this.customerCanvasRef;

    if (current === null) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_PROOF_IMAGES);

    const result = await current.getProofImages();

    enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_PROOF_IMAGES);

    if (!result) return;

    return result;
  }

  async approveLayout(): Promise<void> {
    const { client } = this.props;
    const { selectedClientLayout, clientLayouts } = this.state;

    if (!client) return;

    const layout = (await this.saveLayout()) as ClientLayout;

    if (!layout) return;

    const approveResult = (await putClientLayout(client.id, {
      ...selectedClientLayout,
      ...{ state: 'APPROVED' },
    })) as ClientLayout;

    if (!approveResult.stateFileId) return;

    const index = clientLayouts.findIndex(
      clientLayout => clientLayout.id === selectedClientLayout?.id
    );

    this.setState({
      clientLayouts: [
        ...clientLayouts.slice(0, index),
        ...clientLayouts.slice(index + 1),
      ],
    });
  }

  async downloadProofImages(imageURLs: string[]): Promise<void> {
    const { client, enableLoadingOverlay } = this.props;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW);

    const images = await downloadLayoutProofImages(imageURLs);

    const zip = new JSZip();

    const imageFolder = zip.folder(
      `layout-${client?.name ?? ''}-${moment().format('DDMMYY-HH-mm-ss')}`
    );

    if (imageFolder === null) return;

    images.forEach((img, index) =>
      imageFolder.file(`layout-${client?.name}-page${index + 1}.jpg`, img)
    );

    zip
      .generateAsync({ type: 'blob' })
      .then(content =>
        saveAs(
          content,
          `layout-${client?.name ?? ''}-${moment().format(
            'DDMMYY-HH-mm-ss'
          )}.zip`
        )
      )
      .finally(() =>
        enableLoadingOverlay(false, REQUEST_IDENTIFIER_DOWNLOAD_PREVIEW)
      );
  }

  showLayoutNameModal(showLayoutNameModal: boolean): void {
    this.setState({ showLayoutNameModal });
  }

  render(): JSX.Element {
    const { showLayoutNameModal, selectedClientLayout } = this.state;
    const { selectedPrintTemplate, client, enableLoadingOverlay } = this.props;

    return (
      <Row className="no-gutters layout-container">
        {selectedPrintTemplate ? (
          <>
            <LayoutNameModal
              ref={this.layoutNameModalRef}
              saveLayout={this.saveLayout}
              showModal={this.showLayoutNameModal}
              show={showLayoutNameModal}
            />
            <Col md={12} lg={8} xl={9} className="customer-canvas-container">
              <CustomerCanvas
                ref={this.customerCanvasRef}
                client={client}
                selectedPrintTemplate={selectedPrintTemplate}
                enableLoadingOverlay={enableLoadingOverlay}
              />
            </Col>
            <Col md={12} lg={4} xl={3} className="layout-buttons">
              <div className="layout-button">
                <Button className="ci-button" onClick={this.onClickSave}>
                  {BUTTON_TITLE_SAVE}
                </Button>
              </div>
              {selectedClientLayout && (
                <div className="layout-button">
                  <Button className="ci-button" onClick={this.onClickSaveAs}>
                    {BUTTON_TITLE_SAVE_AS}
                  </Button>
                </div>
              )}
              <div className="layout-button">
                <Button className="ci-button" onClick={this.onClickApprove}>
                  {BUTTON_TITLE_APPROVE}
                </Button>
              </div>
              <div className="layout-button">
                <Button className="ci-button" onClick={this.onClickPreview}>
                  {BUTTON_TITLE_PREVIEW}
                </Button>
              </div>
              <div className="layout-button">
                <Button className="ci-button" onClick={this.onClickDownload}>
                  {BUTTON_TITLE_DOWNLOAD}
                </Button>
              </div>
            </Col>
          </>
        ) : (
          <div className="no-layout">{NO_PRINT_TEMPLATE_SELECTED}</div>
        )}
      </Row>
    );
  }
}
