import moment from 'moment';
import { DEFAULT_PRODUCTS } from '../constants/constants';

import {
  User,
  Client,
  ClientLocation,
  Product,
  ProductPrice,
  KaufDaItemSettings,
  SocialMediaItemSettings,
  SocialMediaTargetGroup,
  AdditionalOptionSettings,
  DistributionAppointment,
  PriceResult,
  LocationPrice,
  TotalPrice,
  ClientLayout,
} from '../@types/Common.d';
import { Area } from '../@types/Area.d';
import { priceString } from './convetUtil';

export const extractDistributionAppointment = (
  responseDistributionAppointment: any
): DistributionAppointment => {
  const { id, name, date, type } = responseDistributionAppointment;

  const cDate = moment(date);

  return { id, name, date: cDate, type } as DistributionAppointment;
};

export const extractDistributionAppointments = (
  responseDistributionAppointments: any[]
): DistributionAppointment[] =>
  responseDistributionAppointments.map(responseDistributionAppointment =>
    extractDistributionAppointment(responseDistributionAppointment)
  );

export const extractAdditionalOption = (
  responseAdditionalOption: any
): AdditionalOptionSettings => {
  const {
    data,
    enabled,
    id,
    platform,
    mode,
    price,
    type,
  } = responseAdditionalOption;

  let optionsData;

  try {
    optionsData = JSON.parse(data);
  } catch (e) {
    optionsData = {};
  }

  if (type === 'KAUFDA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultHomepage: optionsData.homepage ?? optionsData.defaultHomepage,
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionHomepages: optionsData.presetSelectionHomepages ?? [],
      sortProperty: 'type',
    } as KaufDaItemSettings;
  if (type === 'SOCIALMEDIA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      platform,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultBudget: optionsData.budget ?? optionsData.defaultBudget,
      defaultTargetGroups: (optionsData.defaultTargetGroups?.map(
        (group: any) =>
          ({ id: group.id, name: group.name } as SocialMediaTargetGroup)
      ) ?? []) as SocialMediaTargetGroup[],
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionBudgets: optionsData.presetSelectionBudgets ?? [],
      presetSelectionTargetGroups:
        optionsData.presetSelectionTargetGroups ?? [],
      sortProperty: 'platform',
    } as SocialMediaItemSettings;

  return { id, type } as AdditionalOptionSettings;
};

export const extractAdditionalOptions = (
  responseAdditionalOptions: any
): AdditionalOptionSettings[] =>
  (responseAdditionalOptions?.map((responseAdditionalOption: any) =>
    extractAdditionalOption(responseAdditionalOption)
  ) ?? []) as AdditionalOptionSettings[];

export const parseUser = (userData: any): User => {
  const { email, forename, id, salutation, surname } = userData;
  return {
    email,
    prename: forename,
    id,
    salutation,
    lastname: surname,
    avatar: 'user_placeholder',
  } as User;
};

export const parseClient = (clientData: any): Client => {
  const { id, name, transmissionType, weekparts, showPrice } = clientData;

  return {
    id,
    name,
    transmissionType,
    weekparts: weekparts.sort(),
    showPrice,
    planLayout: true,
  } as Client;
};

export const parseClients = (clientsData: any): Client[] =>
  clientsData
    .map((clientData: any) => parseClient(clientData))
    .sort((a: Client, b: Client) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      return 0;
    });

export const extractClientLocation = ({
  addressName,
  city,
  colorSelectedFill,
  housenumber,
  id,
  // TODO img,
  // TODO lat,
  // TODO lon,
  name,
  number,
  postcode,
  street,
  billingDefault,
}: any): ClientLocation =>
  ({
    addressName,
    city,
    colorSelectedFill,
    housenumber,
    id,
    name,
    number,
    postcode,
    selected: false,
    street,
    show: true,
    billingDefault: billingDefault ?? false,
    areas: [] as Area[],
  } as ClientLocation);

export const extractClientLocations = (
  responseClientLocations: any
): ClientLocation[] =>
  responseClientLocations
    .map((responseClientLocation: any) =>
      extractClientLocation(responseClientLocation)
    )
    .sort((a: ClientLocation, b: ClientLocation) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      return 0;
    });

export const extractProduct = ({
  id,
  name,
  printDocRequired,
  productPrices = [],
}: any): Product =>
  ({
    id,
    name,
    printDocRequired,
    productPrices: productPrices.map(
      (productPrice: any) =>
        ({
          id: productPrice.id,
          price: productPrice.price,
          quantityFrom: productPrice.quantityFrom,
          quantityTo: productPrice.quantityTo,
        } as ProductPrice)
    ),
  } as Product);

export const extractProducts = (responseProducts: any): Product[] =>
  responseProducts
    .map((responseProduct: any) => extractProduct(responseProduct))
    .sort((a: Product, b: Product) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

export const parseFullClient = ({
  id,
  transmissionType,
  products,
  name,
  locations,
  weekparts,
  showPrice,
  additionalOptions,
  distributionAppointments,
  distributionDateType,
  billingType,
  billCity,
  billHousenumber,
  billName,
  billPostcode,
  billStreet,
  planLayout,
}: any): Client =>
  ({
    id,
    name,
    transmissionType,
    weekparts: weekparts.sort(),
    clientLocations: extractClientLocations(locations),
    products:
      products.length > 0 ? extractProducts(products) : DEFAULT_PRODUCTS,
    showPrice,
    additionalOptions: extractAdditionalOptions(additionalOptions),
    distributionAppointments: extractDistributionAppointments(
      distributionAppointments
    ),
    distributionDateType,
    billingType,
    billingAddress: {
      city: billCity,
      housenumber: billHousenumber,
      name: billName,
      postcode: billPostcode,
      street: billStreet,
    },
    planLayout: true,
  } as Client);

export const extractLocationPrice = (responsePrice: any): LocationPrice => {
  const {
    id,
    totalDistribution,
    totalDistributionInclusiveVat,
    vat,
    totalDistributionPerThousand,
    totalKaufDa,
    circulation,
  } = responsePrice;

  return {
    id,
    total: priceString(totalDistribution),
    totalInclusiveVat: priceString(totalDistributionInclusiveVat),
    vat: priceString(vat),
    totalPerThousand: priceString(totalDistributionPerThousand),
    totalKaufDa: priceString(totalKaufDa),
    circulation: +circulation,
  } as LocationPrice;
};

export const extractTotalPrice = (responsePrice: any): TotalPrice => {
  const {
    id,
    total,
    totalInclusiveVat,
    vat,
    totalPerThousand,
    subtotalDistributionPerThousand,
    subtotalDistribution,
    subtotalKaufDa,
    subtotalPrint,
    subtotalPrintPerThousand,
  } = responsePrice;

  return {
    id,
    total: priceString(total),
    totalInclusiveVat: priceString(totalInclusiveVat),
    vat: priceString(vat),
    totalPerThousand: priceString(totalPerThousand),
    subtotalDistributionPerThousand: priceString(
      subtotalDistributionPerThousand
    ),
    subtotalDistribution: priceString(subtotalDistribution),
    subtotalKaufDa: priceString(subtotalKaufDa),
    subtotalPrint: priceString(subtotalPrint),
    subtotalPrintPerThousand: priceString(subtotalPrintPerThousand),
  } as TotalPrice;
};

export const extractPrices = (responsePrices: any): PriceResult => {
  const {
    total,
    totalInclusiveVat,
    vat,
    locations,
    subtotalDistribution,
    subtotalDistributionPerThousand,
    subtotalKaufDa,
  } = responsePrices;

  return {
    price: extractTotalPrice({
      total,
      totalInclusiveVat,
      vat,
      subtotalDistribution,
      subtotalDistributionPerThousand,
      subtotalKaufDa,
    }),
    subsidiaryPrices: locations.map((location: any) =>
      extractLocationPrice(location)
    ),
  };
};

export const extractClientLayout = (responseLayout: any): ClientLayout => {
  const { id, name, url, state, stateFileId } = responseLayout;

  return { id, name, url, state, stateFileId } as ClientLayout;
};

export const extractClientLayouts = (responseLayouts: any[]): ClientLayout[] =>
  responseLayouts.map(responseLayout => extractClientLayout(responseLayout));
