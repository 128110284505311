import '../../styles/StepSelection.scss';

import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import StepSelectionItem from './StepSelectionItem';

import {
  STEP_SELECTION_LAYOUT,
  STEP_SELECTION_DISTRIBUTION,
  STEP_SELECTION_HEADER_TEXT,
} from '../../constants/labels';

import {
  StepSelectionContainerState,
  StepSelectionContainerProps,
} from '../../@types/StepSelection.d';
import { STEP_FORMAT, STEP_DISTRIBUTION } from '../../constants/constants';

export default class StepSelectionContainer extends React.Component<
  StepSelectionContainerProps,
  StepSelectionContainerState
> {
  constructor(props: StepSelectionContainerProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const { advanceStep } = this.props;

    return (
      <Row className="no-gutters h-100">
        <Col className="selection-container">
          <Row className="no-gutters selection-header-container">
            <Col className="selection-header">{STEP_SELECTION_HEADER_TEXT}</Col>
          </Row>
          {/* <Row className="no-gutters selection-item-container"> */}
          <div className="selection-item-container">
            <div className="choice-container">
              <StepSelectionItem
                advanceStep={advanceStep}
                title={STEP_SELECTION_LAYOUT}
                targetStep={STEP_FORMAT}
              />
            </div>
            <div className="choice-container">
              <StepSelectionItem
                advanceStep={advanceStep}
                title={STEP_SELECTION_DISTRIBUTION}
                targetStep={STEP_DISTRIBUTION}
              />
            </div>
          </div>
          {/* </Row> */}
        </Col>
      </Row>
    );
  }
}
