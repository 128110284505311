/* eslint-disable max-classes-per-file */
/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select';

import ContentContainer from './ContentContainer';

import {
  OFFER_MODAL_PRINT_MASTER_TITLE,
  OFFER_MODAL_PRINT_MASTER_PER_SUBSIDIARY_LABEL,
  OFFER_MODAL_PRINT_MASTER_FILE_LABEL,
  OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_PER_SUBSIDIARY_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER,
  NO_SUBSIDIARIES_SELECTED,
  OFFER_MODAL_PRINT_USE_LAYOUT_LABEL,
  OFFER_MODAL_PRINT_LAYOUT_SELECT_LABEL,
  OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER,
} from '../../../constants/labels';
import {
  EXTRA_COPIES_ARRAY,
  selectPickerTheme,
} from '../../../constants/constants';

import {
  PrintDataProps,
  PrintFileItemProps,
  PrintFileItemState,
  PrintDataState,
} from '../../../@types/Modal.d';
import {
  MasterFile,
  ExtraCopy,
  LayoutSelection,
} from '../../../@types/Common.d';

export default class PrintData extends React.Component<
  PrintDataProps,
  PrintDataState
> {
  constructor(props: PrintDataProps) {
    super(props);

    this.state = { useLayout: false };

    this.onChangePrintPerSubsidiary = this.onChangePrintPerSubsidiary.bind(
      this
    );
    this.onChangeExtraCopiesPerSubsidiary = this.onChangeExtraCopiesPerSubsidiary.bind(
      this
    );
    this.onChangeMasterFiles = this.onChangeMasterFiles.bind(this);
    this.onChangeLayoutSelection = this.onChangeLayoutSelection.bind(this);
    this.onChangeExtraCopies = this.onChangeExtraCopies.bind(this);
    this.onChangeUseLayout = this.onChangeUseLayout.bind(this);
  }

  onChangePrintPerSubsidiary(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changePrintPerSubsidiary } = this.props;

    changePrintPerSubsidiary(event.currentTarget.checked);
  }

  onChangeExtraCopiesPerSubsidiary(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { changeExtraCopiesPerSubsidiary } = this.props;

    changeExtraCopiesPerSubsidiary(event.currentTarget.checked);
  }

  onChangeMasterFiles(
    event: React.ChangeEvent<HTMLInputElement>,
    subsidiaryId: number
  ): void {
    event.stopPropagation();
    event.preventDefault();

    const { changeMasterFiles } = this.props;

    if (event.currentTarget.files !== null)
      changeMasterFiles({
        masterFile: event.currentTarget.files[0],
        subsidiaryId,
      } as MasterFile);
  }

  onChangeLayoutSelection(layout: any, subsidiaryId: number): void {
    if (layout && layout !== null) {
      const { changeMasterFiles } = this.props;

      const layoutId = layout.value as number;

      changeMasterFiles({ layoutId, subsidiaryId } as LayoutSelection);
    }
  }

  onChangeExtraCopies(data: any, subsidiaryId: number): void {
    const { changeExtraCopies } = this.props;

    if (data && data !== null)
      changeExtraCopies({ extraCopies: data.value, subsidiaryId } as ExtraCopy);
  }

  onChangeUseLayout(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ useLayout: event.currentTarget.checked });
  }

  render(): JSX.Element {
    const {
      client,
      product,
      printPerSubsidiary,
      extraCopriesPerSubsidiary,
      masterFiles,
      clientLayouts,
      selectedSubsidiaries,
    } = this.props;
    const { useLayout } = this.state;

    return (
      <div>
        {product.printDocRequired && (
          <ContentContainer title={OFFER_MODAL_PRINT_MASTER_TITLE}>
            {client && (
              <>
                <Form.Row>
                  <Col xs={12} xl={6} className="switch-container">
                    <Form.Check
                      type="switch"
                      id="print-per-subsidiary-switch"
                      label=""
                      checked={printPerSubsidiary}
                      onChange={this.onChangePrintPerSubsidiary}
                    />
                    <div className="sub-section-title">
                      {OFFER_MODAL_PRINT_MASTER_PER_SUBSIDIARY_LABEL}
                    </div>
                  </Col>
                </Form.Row>
                <div className="separator" />
              </>
            )}
            <Form.Row>
              <Col>
                {printPerSubsidiary ? (
                  selectedSubsidiaries.length > 0 ? (
                    selectedSubsidiaries.map(subsidiary => (
                      <PrintFileItem
                        subsidiary={subsidiary}
                        clientLayouts={clientLayouts}
                        onChangeLayoutSelection={this.onChangeLayoutSelection}
                        masterFileName={
                          (masterFiles.find(
                            file => file.subsidiaryId === subsidiary.id
                          ) as MasterFile)?.masterFile?.name ?? ''
                        }
                        onChangeMasterFiles={this.onChangeMasterFiles}
                      />
                    ))
                  ) : (
                    <Col className="empty-list-container">
                      {NO_SUBSIDIARIES_SELECTED}
                    </Col>
                  )
                ) : (
                  <Row className="print-item-container">
                    <Col
                      xs={6}
                      xl={2}
                      className="custom-text-wrap subsidiary-name p-0"
                    >
                      {OFFER_MODAL_PRINT_MASTER_FILE_LABEL}
                    </Col>
                    <Col xs={6} xl={5} className="p-0">
                      {useLayout ? (
                        <Select
                          // blurInputOnSelect
                          isSearchable={false}
                          options={clientLayouts.map(clientLayout => ({
                            value: clientLayout.id,
                            label: clientLayout.name,
                          }))}
                          placeholder={
                            OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER
                          }
                          onChange={(layout: any) =>
                            this.onChangeLayoutSelection(layout, -1)
                          }
                          theme={(theme: any) => selectPickerTheme(theme)}
                          styles={{
                            menuPortal: provided => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnScroll={false}
                        />
                      ) : (
                        <Form.File
                          id="master-file-input"
                          data-browse={OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL}
                          accept=".pdf"
                          label={
                            (masterFiles.find(
                              file => file.subsidiaryId === -1
                            ) as MasterFile)?.masterFile.name ?? ''
                          }
                          custom
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onChangeMasterFiles(event, -1)}
                        />
                      )}
                    </Col>
                    <Col xs={4} xl={2}>
                      <Form.Check
                        type="switch"
                        id="use-layout--1-switch"
                        label={OFFER_MODAL_PRINT_USE_LAYOUT_LABEL}
                        checked={useLayout}
                        onChange={this.onChangeUseLayout}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Form.Row>
          </ContentContainer>
        )}
        <ContentContainer title={OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL}>
          {client && (
            <>
              <Form.Row>
                <Col xs={12} xl={6} className="switch-container">
                  <Form.Check
                    type="switch"
                    id="extra-copies-per-subsidiary-switch"
                    label=""
                    checked={extraCopriesPerSubsidiary}
                    onChange={this.onChangeExtraCopiesPerSubsidiary}
                  />
                  <div className="sub-section-title">
                    {OFFER_MODAL_PRINT_EXTRA_COPIES_PER_SUBSIDIARY_LABEL}
                  </div>
                </Col>
              </Form.Row>
              <div className="separator" />
            </>
          )}
          <Form.Row>
            <Col>
              {extraCopriesPerSubsidiary ? (
                selectedSubsidiaries.length > 0 ? (
                  selectedSubsidiaries.map(subsidiary => (
                    <Row className="print-item-container">
                      <Col
                        xs={6}
                        xl={2}
                        className="custom-text-wrap subsidiary-name p-0"
                      >
                        {subsidiary.name}
                      </Col>
                      <Col xs={6} xl={2} className="p-0">
                        <Select
                          // blurInputOnSelect
                          isSearchable={false}
                          options={EXTRA_COPIES_ARRAY}
                          onChange={data =>
                            this.onChangeExtraCopies(data, subsidiary.id)
                          }
                          theme={(theme: any) => selectPickerTheme(theme)}
                          styles={{
                            menuPortal: provided => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          placeholder={
                            OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER
                          }
                        />
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Col className="empty-list-container">
                    {NO_SUBSIDIARIES_SELECTED}
                  </Col>
                )
              ) : (
                <Row className="print-item-container">
                  <Col
                    xs={6}
                    xl={2}
                    className="custom-text-wrap subsidiary-name p-0"
                  >
                    {OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL}
                  </Col>
                  <Col xs={6} xl={2} className="p-0">
                    <Select
                      // blurInputOnSelect
                      isSearchable={false}
                      options={EXTRA_COPIES_ARRAY}
                      onChange={data => this.onChangeExtraCopies(data, -1)}
                      theme={(theme: any) => selectPickerTheme(theme)}
                      styles={{
                        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      placeholder={
                        OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Form.Row>
        </ContentContainer>
      </div>
    );
  }
}

class PrintFileItem extends React.Component<
  PrintFileItemProps,
  PrintFileItemState
> {
  constructor(props: PrintFileItemProps) {
    super(props);

    this.state = { useLayout: false };

    this.onChangeUseLayout = this.onChangeUseLayout.bind(this);
  }

  onChangeUseLayout(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ useLayout: event.currentTarget.checked });
  }

  render(): JSX.Element {
    const {
      subsidiary,
      masterFileName,
      clientLayouts,
      onChangeMasterFiles,
      onChangeLayoutSelection,
    } = this.props;
    const { useLayout } = this.state;

    return (
      <Row className="print-item-container">
        <Col xs={4} xl={2} className="custom-text-wrap subsidiary-name p-0">
          {subsidiary.name}
        </Col>
        <Col xs={4} xl={5}>
          {useLayout ? (
            <Select
              // blurInputOnSelect
              isSearchable={false}
              options={clientLayouts.map(clientLayout => ({
                value: clientLayout.id,
                label: clientLayout.name,
              }))}
              placeholder={OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER}
              onChange={(layout: any) =>
                onChangeLayoutSelection(layout, subsidiary.id)
              }
              theme={(theme: any) => selectPickerTheme(theme)}
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              closeMenuOnScroll={false}
            />
          ) : (
            <Form.File
              id={`subsidiary-${subsidiary.id}-master-file-input`}
              data-browse={OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL}
              accept=".pdf"
              label={masterFileName}
              custom
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeMasterFiles(event, subsidiary.id)
              }
            />
          )}
        </Col>
        <Col xs={4} xl={2}>
          <Form.Check
            type="switch"
            id={`use-layout${subsidiary.id}-switch`}
            label={OFFER_MODAL_PRINT_USE_LAYOUT_LABEL}
            checked={useLayout}
            onChange={this.onChangeUseLayout}
          />
        </Col>
      </Row>
    );
  }
}
